<app-widgets-overlay *ngIf="loadedError"></app-widgets-overlay>
<app-widgets-overlay *ngIf="loaded && matches.length === 0" type="match-ticket"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <swiper [config]="slideConfig" #swiper [(index)]="slideIndex" [disabled]="disable">
        <ng-template swiperSlide *ngFor="let match of matches; trackBy: trackByFn">
            <div class="widget-match-container d-flex">
                <div class="widget-match-participant d-flex flex-column align-items-center justify-content-center">
                    <div class="d-flex flex-column justify-content-start widget-match-participant-container">
                        <div class="widget-match-participant-img">
                            <img
                                (error)="imageError($event)"
                                [attr.src]="match.participants[0].image | responsive : 'participant' : 'medium'"
                                alt="{{ match.participants[0].name }}"
                                class="img-fluid app-cursor"
                            />
                        </div>
                        <div class="widget-match-participant-team" [class.font-weight-bold]="isWinnerHome(match)">
                            {{ match.participants[0].name }}
                        </div>
                    </div>
                </div>
                <div class="widget-match-data d-flex flex-column align-items-center justify-content-center">
                    <img
                        [src]="'/img/' + client.getName() + '.png'"
                        [alt]="client.getName()"
                        class="widget-match-logo"
                    />
                    <div class="widget-match-data-text">
                        {{ match.start_date | livDate : 'localDateTimeShort' }}
                    </div>
                    <div class="widget-match-data-time" *ngIf="isRunning(match) && match.display_time">
                        <app-match-time
                            [time]="match.minute"
                            [addTime]="match.added_time"
                            [code_state]="match.code_state"
                            [lastTimeUpdate]="match?.last_time_update"
                            [sportName]="match.sport_code_name"
                            [periodLength]="match.period_length"
                        ></app-match-time>
                    </div>
                    <div class="widget-match-data-score" [class.widget-match-data-score--live]="isLive(match)">
                        <app-score
                            [sport]="match.sport_code_name"
                            [data]="match.score"
                            [match_state]="match.match_state"
                            [onlyPeriods]="false"
                            [onlyFinal]="true"
                            [type]="'short'"
                        ></app-score>
                    </div>
                    <div class="widget-match-data-text">
                        <app-score
                            [sport]="match.sport_code_name"
                            [match_state]="match.match_state"
                            [data]="match.score"
                            [onlyPeriods]="true"
                            [type]="'short'"
                        ></app-score>
                    </div>
                    <div class="widget-match-data-text mt-3" [class.font-weight-bold]="isLive(match)">
                        {{ 'status.' + match.sport_code_name + '.' + match.code_state | translate }}
                    </div>
                </div>
                <div class="widget-match-participant d-flex flex-column align-items-center justify-content-center">
                    <div class="d-flex flex-column justify-content-start widget-match-participant-container">
                        <div class="widget-match-participant-img">
                            <img
                                (error)="imageError($event)"
                                [attr.src]="match.participants[1].image | responsive : 'participant' : 'medium'"
                                alt="{{ match.participants[1].name }}"
                                class="img-fluid app-cursor"
                            />
                        </div>

                        <div class="widget-match-participant-team" [class.font-weight-bold]="isWinnerAway(match)">
                            {{ match.participants[1].name }}
                        </div>
                    </div>
                </div>
            </div>
            <h6 class="match-info-head mb-0 bg-light p-3 d-flex ng-star-inserted" data-cy="match-info-head">
                <div class="img-circle mr-2 rounded-circle">
                    <img [src]="match.image" height="16" alt="{{ match.category_name }}: {{ match.tournament_name }}" />
                </div>
                <div>
                    <span class="font-weight-bold text-uppercase"
                        ><a class="text-reset">{{ match.category_name }}: {{ match.tournament_name }}</a></span
                    >
                    <span *ngIf="match.round_name != null"> - {{ match.round_name }}</span>
                    <span *ngIf="match.round_name == null && match.round != null">
                        - {{ match.round }}. {{ 'web.round' | translate | transinit }}</span
                    >
                </div>
            </h6>
        </ng-template>
    </swiper>
</ng-container>

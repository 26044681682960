/**
 *  Widgets - Double result (Tennis)
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { ClientService } from '@/services/client.service';
import { CodeState, MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';
import { MatchType } from '@/interfaces/league-table.interface';

@Component({
    selector: 'widgets-double-result',
    templateUrl: './double-result.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DoubleResultComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;


    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;

            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }


    public win(type: 'home' | 'away'): number {
        let win = 0;
        let total = 0;

        const reversed = this.data![type]?.form.length > this.num
            ? this.data?.[type]?.form.slice(1) : this.data?.[type]?.form;

        if (!reversed) {
            return 0;
        }
        reversed.forEach((f) => {
            if (f.code_state === CodeState.ENDED && f.score && f.score_period_1) {
                const [home, away] = f.score.split(':');
                const [home1, away1] = f.score_period_1.split(':');
                const isHome = this.data?.[type].name === f.home_team;

                const isMatchWin = (isHome && home > away) || (!isHome && home < away);
                const winFirstPeriod = (isHome && home1 > away1) || (!isHome && away1 > home1);
                if (isMatchWin && winFirstPeriod) {
                    win += 1;
                }

                total += 1;
            }
        });

        return win / total * 100;
    }

    public lose(type: 'home' | 'away'): number {
        let lose = 0;
        let total = 0;

        const reversed = this.data![type]?.form.length > this.num
            ? this.data?.[type]?.form.slice(1) : this.data?.[type]?.form;

        if (!reversed) {
            return 0;
        }
        reversed.forEach((f) => {
            if (f.code_state === CodeState.ENDED && f.score && f.score_period_1) {
                const [home, away] = f.score.split(':');
                const [home1, away1] = f.score_period_1.split(':');
                const isHome = this.data?.[type].name === f.home_team;

                const isMatchLose = (isHome && home < away) || (!isHome && home > away);
                const winFirstPeriod = (isHome && home1 > away1) || (!isHome && away1 > home1);
                if (isMatchLose && winFirstPeriod) {
                    lose += 1;
                }

                total += 1;
            }
        });

        return lose / total * 100;
    }


    get homeWinWidth(): number {
        const total = this.win('home') + this.win('away');
        return Math.round((this.win('home') / total) * 100);
    }

    get awayWinWidth(): number {
        return 100 - this.homeWinWidth;
    }

    get homeLoseWidth(): number {
        const total = this.lose('home') + this.lose('away');
        return Math.round((this.lose('home') / total) * 100);
    }

    get awayLoseWidth(): number {
        return 100 - this.homeLoseWidth;
    }


    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.doubleResult.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),

                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.finished();
                },
            );
    }
}

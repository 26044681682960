<div class="d-flex justify-content-between">
    <div class="widget-form-participant widget-form-participant--home">{{ home }}%</div>
    <div class="widget-form-participant-text align-self-end">
        {{ 'web.participant_form' | translate }}
    </div>
    <div class="widget-form-participant widget-form-participant--away">{{ away }}%</div>
</div>

<div class="d-flex">
    <div class="widget-form-participant-bar widget-form-participant-bar--home" [style.width.%]="homeWidth">
        <ng-content select=".home"></ng-content>
    </div>
    <div class="widget-form-participant-bar widget-form-participant-bar--away" [style.width.%]="awayWidth">
        <ng-content select=".away"></ng-content>
    </div>
</div>

<div [hidden]="true">
    <app-participant-form [form]="formHome" (onLoad)="home = $event"></app-participant-form>
    <app-participant-form [form]="formAway" (onLoad)="away = $event"></app-participant-form>
</div>

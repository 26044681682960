/**
 *  Widgets - Half/Full time
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { AvgData, AvgGoals, Match, Periods, Result } from '@widgets/interfaces/widget.interface';
import APP_CONFIG from '@config/app.config';
import CONFIG from '@widgets/config/widget.config';
import { TranslateService } from '@ngx-translate/core';

import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-hf-ft',
    templateUrl: './hf-ft.component.html',
    styleUrls: ['./styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HfFtComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: AvgData | null = null;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
        public trans: TranslateService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    public isHome(participant: AvgGoals, match: Match): boolean {
        return participant.id === match.home_id;
    }

    public isAway(participant: AvgGoals, match: Match): boolean {
        return participant.id === match.away_id;
    }

    private load(): void {
        const iso: string = this.lang.getLangSnapshot() ?? APP_CONFIG.defaultLang;
        let participant1: number | null = null;
        let participant2: number | null = null;

        let sportName: string;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.hfft.availSports.includes(match.sport_code_name)),
                tap((match: MatchData) => {
                    sportName = match.sport_code_name;
                }),
                mergeMap(
                    (match: MatchData): Observable<AvgData> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        participant1 = p1;
                        participant2 = p2;
                        return this.demo.avgGoals(p1, p2, iso);
                    },
                ),
                map((data: AvgData) => {
                    data.participant1 = {
                        ...data.participant1,
                        // @ts-ignore
                        periodsLength: CONFIG.hfft.periods[sportName],
                        id: participant1,
                    };
                    data.participant2 = {
                        ...data.participant2,
                        // @ts-ignore
                        periodsLength: CONFIG.hfft.periods[sportName],
                        id: participant2,
                    };
                    data.participant1.matches = data.participant1.matches.map(this.mapHtFt(data.participant1));
                    data.participant2.matches = data.participant2.matches.map(this.mapHtFt(data.participant2));

                    return data;
                }),

                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.finished();
                },
            );
    }

    public resultCssClass(period: Periods, match: Match): Record<string, boolean> {
        const key = `hfft-team__bar__field--${match.hfft![period]}`;
        return { [key]: true };
    }

    public resultTrans(period: Periods, match: Match): string {
        if (match.hfft![period] === 'win') {
            return this.trans.instant('web.last_matches_win_short');
        }

        if (match.hfft![period] === 'lose') {
            return this.trans.instant('web.last_matches_lose_short');
        }

        return this.trans.instant('web.last_matches_draw_short');
    }

    private mapHtFt(data: AvgGoals) {
        return (match: Match): Match => {
            const periodHfHome = match.score.periods[0].home_team;
            const periodHfAway = match.score.periods[0].away_team;
            let htResult: Result = 'draw';

            const periodFtHome = match.score.home_team;
            const periodFtAway = match.score.away_team;
            let ftResult: Result = 'draw';

            if (
                (this.isHome(data, match) && periodHfHome > periodHfAway) ||
                (this.isAway(data, match) && periodHfHome < periodHfAway)
            ) {
                htResult = 'win';
            } else if (
                (this.isHome(data, match) && periodHfHome < periodHfAway) ||
                (this.isAway(data, match) && periodHfHome > periodHfAway)
            ) {
                htResult = 'lose';
            }

            if (
                (this.isHome(data, match) && periodFtHome > periodFtAway) ||
                (this.isAway(data, match) && periodFtHome < periodFtAway)
            ) {
                ftResult = 'win';
            } else if (
                (this.isHome(data, match) && periodFtHome < periodFtAway) ||
                (this.isAway(data, match) && periodFtHome > periodFtAway)
            ) {
                ftResult = 'lose';
            }

            match.hfft = { ht: htResult, ft: ftResult };
            return match;
        };
    }
}

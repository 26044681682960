/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/**
 *  Spread -  Hockey + Basketball
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2025 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { forEach } from 'lodash-es';

import { FormControl } from '@angular/forms';

import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';
import { MatchType } from '@/interfaces/league-table.interface';


@Component({
    selector: 'widgets-spread',
    templateUrl: './spread.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SpreadComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public sportName: string;

    public typeControl: FormControl;

    public type: string = 'all';

    public num = 20;

    public periods: {[prop: string]:{[prop: string]: string[]}} = {
        basketball: {
            all: ['score'],
            '1_half': ['score_period_1', 'score_period_2'],
            '2_half': ['score_period_3', 'score_period_4'],
            '1_quater': ['score_period_1'],
            '2_quater': ['score_period_2'],
            '3_quater': ['score_period_3'],
            '4_quater': ['score_period_4'],
        },
        'ice-hockey': {
            all: ['score'],
            '1_third': ['score_period_1'],
            '2_third': ['score_period_2'],
            '3_third': ['score_period_3'],
        },
    };

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();


        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });

        this.typeControl = new FormControl('all');
        this.typeControl.valueChanges.subscribe((val: string) => {
            this.type = val;
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }


    /**
     * @returns [number, number] - [for, agains]
     */
    public periodsAvg(side: 'home' | 'away', matchPart: string): [number, number] {
        let totalFor = 0;
        let totalAgains = 0;
        let count = 0;

        const reversed = this.data![side].form;


        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && this.sportName && this.periods[this.sportName]) {
                const isHome = this.data![side].name === f.home_team;

                forEach(this.periods[this.sportName]![matchPart], (period: string) => {
                    if (period in f && f[period]) {
                        const [home = 0, away = 0] = (f[period] as string).split(':');
                        if (isHome) {
                            totalFor += +home;
                            totalAgains += +away;
                        } else {
                            totalFor += +away;
                            totalAgains += +home;
                        }
                    }
                });


                count += 1;
            }
        });

        return [(totalFor / count), (totalAgains / count)];
    }


    public total(period: string, index: number): number {
        return +this.periodsAvg('home', period)[index]! + +this.periodsAvg('away', period)[index]!;
    }

    public width(side: 'home' | 'away', period: string, index: number): number {
        return Math.round((+this.periodsAvg(side, period)[index]! / this.total(period, index)) * 100);
    }

    public spread(side: 'home' | 'away', period: string): number {
        return (+this.periodsAvg(side, period)[0] - +this.periodsAvg(side, period)[1]);
    }

    get totalSpread(): number {
        const home = +this.spread('home', this.type);
        const away = +this.spread('away', this.type);

        return Math.max(home, away) - Math.min(home, away);
    }

    // private findPeriod(period: string): [string, string] | null {
    //     const periodVal = this.periods.find(p => p[0] === period);

    //     return periodVal![1] ?? null;
    // }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                tap((match: MatchData) => {
                    this.sportName = match.sport_code_name;
                }),
                filter((match: MatchData) => CONFIG.spread.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;

                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { DemoComponent } from '@widgets/pages/demo/demo.component';
import { WidgetsComponent } from '@widgets/pages/widgets/widgets.component';

import { AvgGoalsComponent } from '@widgets/pages/widgets/avg-goals/avg-goals.component';
import { LastMatchesComponent } from '@widgets/pages/widgets/last-matches/last-matches.component';
import { HfFtComponent } from '@widgets/pages/widgets/hf-ft/hf-ft-component';

import { FormComponent } from '@widgets/pages/widgets/form/form.component';
import { ThreeWayComponent } from '@widgets/pages/widgets/3way/3way.component';
import { TwoWayComponent } from '@widgets/pages/widgets/2way/2way.component';
import { FistHalfComponent } from '@widgets/pages/widgets/1st-half/1st-half.component';
import { BothTeamToScoreComponent } from '@widgets/pages/widgets/both-team-score/both-team-score.component';
import { CornersComponent } from '@widgets/pages/widgets/corners/corners.component';
import { UnderOverComponent } from '@widgets/pages/widgets/under-over/under-over.component';
import { CorrectScoreComponent } from '@widgets/pages/widgets/correct-score/correct-score.component';
import { DoubleChanceComponent } from '@widgets/pages/widgets/double-chance/double-chance.component';
import { ScoringPeriodComponent } from '@widgets/pages/widgets/scoring-period/scoring-period.component';
import CONFIG from '@widgets/config/widget.config';
import { GamesWonComponent } from '@widgets/pages/widgets/games-won/games-won.component';
import { TotalSetsComponent } from '@widgets/pages/widgets/total-sets/total-sets.component';
import { NumberOfSetsComponent } from '@widgets/pages/widgets/number-sets/number-sets.component';
import { SetsWonComponent } from '@widgets/pages/widgets/sets-won/sets-won.component';
import { BothToWinSetComponent } from '@widgets/pages/widgets/both-to-win-set/both-to-win-set.component';
import { NoSetWonComponent } from '@widgets/pages/widgets/no-sets-won/no-set-won-set.component';
import { DoubleResultComponent } from '@widgets/pages/widgets/double-result/double-result.component';
import { BothTeamToScore2GoalsComponent } from
    '@widgets/pages/widgets/both-team-score-2goals/both-team-score-2goals.component';
import { BothTeamToScore3GoalsComponent }
    from '@widgets/pages/widgets/both-team-score-3goals/both-team-score-3goals.component';
import { ScoringHalfComponent } from '@widgets/pages/widgets/scoring-half/scoring-half.component';
import { WinHalfComponent } from '@widgets/pages/widgets/win-half/win-half.component';

import { HighestScoringQuaterComponent }
    from '@widgets/pages/widgets/highest-scoring-quater/highest-scoring-quater.component';

import { TotalPointsComponent } from '@widgets/pages/widgets/total-points/total-points.component';
import { HighestScoringHalfComponent }
    from '@widgets/pages/widgets/highest-scoring-half/highest-scoring-half.component';
import { BasketWinPrctComponent } from '@widgets/pages/widgets/basket-win-prct/basket-win-prct.component';

import { ScoredEveryPeriodComponent } from './pages/widgets/scored-every-period/scored-every-period.component';

import { TotalGamesComponent } from './pages/widgets/total-games/total-games.component';


import { DoubelResultPeriodsComponent } from './pages/widgets/double-result-periods/double-result-periods.component';

import { WinEveryPeriodComponent } from '@/modules/widgets/pages/widgets/win-every-period/win-every-period.component';

import { SpreadComponent } from '@/modules/widgets/pages/widgets/spread/spread.component';

import { GoalScoredComponent } from '@/modules/widgets/pages/widgets/goal-scored/goal-scored.component';
import { BestGoalscorerComponent } from '@/modules/widgets/pages/widgets/best-goalscore/best-goalscorer.component';
import { MatchWidgetResolverService } from '@/pages/page-sport/match-widget/match-widget-resolver.service';
import { MatchFlashComponent } from '@/modules/widgets/pages/widgets/match-xs/match-xs.component';
import { Handicap2WayTennisComponent }
    from '@/modules/widgets/pages/widgets/2way-handicap-tennis/2way-handicap-tennis.component';
import { CardsComponent } from '@/modules/widgets//pages/widgets/cards/cards.component';
import { GoalScorerStatsComponent } from '@/modules/widgets/pages/widgets/stats-goalscore/stats-goalscorer.component';
import { WillGameGoToShootoutComponent }
    from '@/modules/widgets/pages/widgets/will-go-to-shootout/will-go-to-shootout.component';
import { WinEveryQuaterComponent } from '@/modules/widgets/pages/widgets/win-every-quater/win-every-quater.component';
import { ScoredEveryQuaterComponent }
    from '@/modules/widgets/pages/widgets/scored-every-quater/scored-every-quater.component';
import { WinBothHalvesComponent } from '@/modules/widgets/pages/widgets/win-both-halves/win-both-halves.component';
import { ScoredEveryHalfComponent }
    from '@/modules/widgets/pages/widgets/scored-every-half/scored-every-half.component';

const routes: Routes = [
    // {
    //     component: DemoComponent,
    //     path: 'demo',
    // },
    {
        component: WidgetsComponent,
        path: 'w',
        children: [
            {
                path: 'goal-scorer',
                component: BestGoalscorerComponent,
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'goal-scorer-stats',
                component: GoalScorerStatsComponent,
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'match-flash',
                component: MatchFlashComponent,
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'scoring-period',
                component: ScoringPeriodComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'scoring-half',
                component: ScoringHalfComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'handicap-2way',
                component: Handicap2WayTennisComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'win-half',
                component: WinHalfComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'will-go-to-shootout',
                component: WillGameGoToShootoutComponent,
                data: {
                    num: 40,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'win-every-period',
                component: WinEveryPeriodComponent,
                data: {
                    num: 40,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'total-sets',
                component: TotalSetsComponent,
                data: {
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'double-result',
                component: DoubleResultComponent,
                data: {
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'total-games',
                component: TotalGamesComponent,
                data: {
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'number-of-sets',
                component: NumberOfSetsComponent,
                data: {
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'both-to-win-set',
                component: BothToWinSetComponent,
                data: {
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'no-set-won',
                component: NoSetWonComponent,
                data: {
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'sets-won-1period',
                component: SetsWonComponent,
                data: {
                    period: 1,
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'sets-won-2period',
                component: SetsWonComponent,
                data: {
                    period: 2,
                    num: 52,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'games-won-1period',
                component: GamesWonComponent,
                data: {
                    period: 1,
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'games-won-2period',
                component: GamesWonComponent,
                data: {
                    period: 2,
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'games-won-3period',
                component: GamesWonComponent,
                data: {
                    period: 3,
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'under-over',
                component: UnderOverComponent,
                data: {
                    restrictSports: CONFIG.underOver,
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'total-hockey',
                component: UnderOverComponent,
                data: {
                    restrictSports: CONFIG.underOver2,
                    num: 20,
                    type: 'total',
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'total-hockey-3w',
                component: UnderOverComponent,
                data: {
                    restrictSports: CONFIG.underOver3,
                    num: 20,
                    equal: true,
                    type: 'total_3w',
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'correct-score',
                component: CorrectScoreComponent,
                data: {
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'corners',
                component: CornersComponent,
                data: {
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'cards',
                component: CardsComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'avg-goals',
                component: AvgGoalsComponent,
                data: {
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'both-team-to-score',
                component: BothTeamToScoreComponent,
                data: {
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'scored-every-period',
                component: ScoredEveryPeriodComponent,
                data: {
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'both-team-to-score-2goals',
                component: BothTeamToScore2GoalsComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'both-team-to-score-3goals',
                component: BothTeamToScore3GoalsComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: '3way',
                component: ThreeWayComponent,
                resolve: {
                    br: MatchWidgetResolverService,
                },
                data: {
                    num: 10,
                },
            },
            {
                path: '2way',
                component: TwoWayComponent,
                data: {
                    num: 51,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: '1st-half',
                component: FistHalfComponent,
                data: {
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'double-chance',
                component: DoubleChanceComponent,
                data: {
                    num: 10,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'highest-scoring-half',
                component: HighestScoringHalfComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'spread',
                component: SpreadComponent,

                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'highest-scoring-quater',
                component: HighestScoringQuaterComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'double-result-period',
                component: DoubelResultPeriodsComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'win-every-quater',
                component: WinEveryQuaterComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'win-both-halves',
                component: WinBothHalvesComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },

            {
                path: 'scored-every-quater',
                component: ScoredEveryQuaterComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },

            {
                path: 'scored-every-half',
                component: ScoredEveryHalfComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },

            {
                path: 'basket-win-prct',
                component: BasketWinPrctComponent,
                data: {
                    num: 20,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'total-points-american-football',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: 'total',
                    uo: 47.5,
                    config: 'totalPointsAmFootball',
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'basketball-total-points',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: 'total',
                    uo: 164.5,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'basketball-total-points-1sthalf',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: '1half',
                    uo: 83.5,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'basketball-total-points-2ndhalf',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: '2half',
                    uo: 80.5,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'basketball-total-points-1q',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: '1q',
                    uo: 41.5,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'basketball-total-points-2q',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: '2q',
                    uo: 41.5,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'basketball-total-points-3q',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: '3q',
                    uo: 40.5,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'basketball-total-points-4q',
                component: TotalPointsComponent,
                data: {
                    num: 20,
                    type: '4q',
                    uo: 40.5,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'form',
                component: FormComponent,
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'goal-scored',
                component: GoalScoredComponent,
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'last-matches',
                component: LastMatchesComponent,
                data: {
                    num: 6,
                },
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
            {
                path: 'hf-ft',
                component: HfFtComponent,
                resolve: {
                    br: MatchWidgetResolverService,
                },
            },
        ],
    },
    { path: '', redirectTo: 'demo', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class WidgetsRoutingModule {}

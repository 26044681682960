/**
 *  Widgets - Match flash
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { AvgData, AvgGoals, Match } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-avg-goals',
    templateUrl: './avg-goals.component.html',
    styleUrls: ['./styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AvgGoalsComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: AvgData | null = null;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    public isHome(participant: AvgGoals, match: Match): boolean {
        return participant.id === match.home_id;
    }

    public isAway(participant: AvgGoals, match: Match): boolean {
        return participant.id === match.away_id;
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let participant1: number | null = null;
        let participant2: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.avgGoals.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<AvgData> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        participant1 = p1;
                        participant2 = p2;
                        return this.demo.avgGoals(p1, p2, iso!, { limit: this.num });
                    },
                ),
                map((data: AvgData) => {
                    data.participant1 = { ...data.participant1, id: participant1 };
                    data.participant2 = { ...data.participant2, id: participant2 };
                    return data;
                }),

                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

/**
 *  Widgets - Total sets Tennis
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';

import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';

import CONFIG from '@widgets/config/widget.config';

import { LangService } from '@/services/lang.service';
import { CodeState, MatchData, Team } from '@/interfaces/match-data.interface';

import { ClientService } from '@/services/client.service';


import { MatchType } from '@/interfaces/league-table.interface';


@Component({
    selector: 'widgets-total-sets',
    templateUrl: './total-sets.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TotalSetsComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public type: string = 'default';

    private numberOfSets: number = 3;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;

            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });

        this.router.paramMap.subscribe((params) => {
            if (params.has('numberOfSets')) {
                this.numberOfSets = +params.get('numberOfSets')!;
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }


    get totalHome(): number {
        let sets = 0;
        let total = 0;

        const reversed = this.data!.home?.form.length > this.num
            ? this.data?.home?.form.slice(1) : this.data?.home?.form;

        if (!reversed) {
            return 0;
        }
        reversed.forEach((f) => {
            if (f.code_state === CodeState.ENDED && f.score) {
                const [home, away] = f.score.split(':');

                sets += +home + +away;
                total += 1;
            }
        });

        return sets / total;
    }

    get totalAway(): number {
        let sets = 0;
        let total = 0;

        const reversed = this.data!.away.form.length > this.num ? this.data?.away.form.slice(1) : this.data?.away.form;

        if (!reversed) {
            return 0;
        }
        reversed.forEach((f) => {
            if (f.code_state === CodeState.ENDED && f.score) {
                const [home, away] = f.score.split(':');

                sets += +home + +away;
                total += 1;
            }
        });

        return sets / total;
    }

    get avgTotal(): number {
        return (this.totalHome + this.totalAway) / 2;
    }

    get homeWidth(): number {
        return (this.totalHome / (this.totalHome + this.totalAway)) * 100;
    }

    get awayWidth(): number {
        return 100 - this.homeWidth;
    }


    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.totalSets.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num, numOfSets: this.numberOfSets });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                filter((data): any => {
                    data.home.form = data.home.form.filter((f: LastForm) => {
                        const [home, away] = f.score.split(':');

                        if (this.numberOfSets === 3) {
                            return +home + +away <= 3 && +home !== 3 && +away !== 3;
                        } if (this.numberOfSets === 5) {
                            return +home + +away > 3 || (+home + +away === 3 && +home !== 2 && +away !== 2);
                        }

                        return false;
                    });
                    data.away.form = data.away.form.filter((f: LastForm) => {
                        const [home, away] = f.score.split(':');

                        if (this.numberOfSets === 3) {
                            return +home + +away <= 3 && +home !== 3 && +away !== 3;
                        } if (this.numberOfSets === 5) {
                            return +home + +away > 3 || (+home + +away === 3 && +home !== 2 && +away !== 2);
                        }

                        return false;
                    });
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

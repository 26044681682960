<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-match-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="avg-goals-title align-self-center">
                {{ 'web.widget_avg_goals_title' | translate }}
            </h1>
            <div class="avg-goals-team d-flex flex-column" *ngIf="data?.participant1 as p">
                <div class="d-flex avg-goals-team__container">
                    <div class="avg-goals-team__name d-flex align-items-center">
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor mr-1"
                        />
                        <div>{{ p.name }}</div>
                    </div>
                    <div class="avg-goals-team__avg align-self-center d-flex flex-wrap justify-content-end mr-2">
                        <div class="">{{ 'web.avg_goals_text' | translate }}:</div>
                        <div class="avg-goals-team__avg-text align-self-center">{{ p.avg_score | number: '1.1' }}</div>
                    </div>
                </div>
                <div class="d-flex justify-content-between avg-goals-team__container2">
                    <div
                        class="avg-goals-team__score"
                        *ngFor="let match of p.matches; trackBy: trackByFn; first as isFirst; index as i"
                        [class.avg-goals-team__score--last]="isFirst"
                    >
                        <div class="avg-goals-team__score-number">
                            <span [class.avg-goals-team__score-number--emph]="isHome(p, match)">
                                {{ match.score.home_team }}</span
                            >:<span [class.avg-goals-team__score-number--emph]="isAway(p, match)">{{
                                match.score.away_team
                            }}</span>
                        </div>
                        <div class="avg-goals-team__score-text">
                            <ng-container *ngIf="isFirst; else notFirst">
                                {{ 'web.last_match' | translate }}
                            </ng-container>
                            <ng-template #notFirst>
                                {{ i + 1 }}
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <img [src]="'/img/' + client.getName() + '.png'" [alt]="client.getName()" class="widget-match-logo" />
            </div>

            <div class="avg-goals-team d-flex flex-column avg-goals-team--second" *ngIf="data?.participant2 as p">
                <div class="d-flex avg-goals-team__container">
                    <div class="avg-goals-team__name d-flex align-items-center">
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor mr-1"
                        />
                        <div>{{ p.name }}</div>
                    </div>
                    <div class="avg-goals-team__avg align-self-center d-flex flex-wrap justify-content-end mr-2">
                        <div class="">{{ 'web.avg_goals_text' | translate }}:</div>
                        <div class="avg-goals-team__avg-text align-self-center">{{ p.avg_score | number: '1.1' }}</div>
                    </div>
                </div>
                <div class="d-flex justify-content-between avg-goals-team__container2">
                    <div
                        class="avg-goals-team__score"
                        *ngFor="let match of p.matches; trackBy: trackByFn; first as isFirst; index as i"
                        [class.avg-goals-team__score--last]="isFirst"
                    >
                        <div class="avg-goals-team__score-number">
                            <span [class.avg-goals-team__score-number--emph]="isHome(p, match)">
                                {{ match.score.home_team }}</span
                            >:<span [class.avg-goals-team__score-number--emph]="isAway(p, match)">{{
                                match.score.away_team
                            }}</span>
                        </div>
                        <div class="avg-goals-team__score-text">
                            <ng-container *ngIf="isFirst; else notFirst">
                                {{ 'web.last_match' | translate }}
                            </ng-container>
                            <ng-template #notFirst>
                                {{ i + 1 }}
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

/**
 *  Widgets - Scoring period avg. goals
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';
import { MatchType } from '@/interfaces/league-table.interface';

@Component({
    selector: 'widgets-scoring-period',
    templateUrl: './scoring-period.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ScoringPeriodComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    public avg(type: 'home' | 'away' = 'home', period: number = 1): number {
        let goals = 0;
        let total = 0;

        const reversed = this.data![type].form.length > this.num
            ? this.data![type].form.slice(1) : this.data![type].form;
        const key = `score_period_${period}`;

        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && key in f) {
                // @ts-ignore
                const [home, away] = f[key].split(':');
                const isHome = this.data![type].name === f.home_team;

                if (isHome) {
                    goals += +home;
                } else {
                    goals += +away;
                }
                total += 1;
            }
        });

        return goals / total;
    }


    public width(side: 'home' | 'away', period: number = 1): number {
        const secondSide = side === 'home' ? 'away' : 'home';
        const main = this.avg(side, period);
        const second = this.avg(secondSide, period);
        const total = +main + +second;

        return Math.round((main / total) * 100) - 10;
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.scoringPeriod.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

/**
 *  Widgets - Corners
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { every, reduce } from 'lodash-es';

import { MatchType } from '@/interfaces/league-table.interface';
import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-1sthalf',
    templateUrl: './corners.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CornersComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public num: number;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num ?? 10;

            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    get cornersHome(): number | string {
        let total = 0;
        let corners = 0;

        const reversed = this.data!.home.form.length > this.num ? this.data!.home.form.slice(1) : this.data!.home.form;

        if (every(reversed, { corners_home: null, corners_away: null })) {
            return 'Ndf';
        }

        corners = reduce(
            reversed,
            (sum: number, f) => {
                let numCorners = 0;
                if (f.code_state !== 'not_started' && (f.corners_home !== null || f.corners_away !== null)) {
                    const isHome = this.data!.home.name === f.home_team;

                    if (isHome) {
                        numCorners += f.corners_home ? +f.corners_home : 0;
                    } else {
                        numCorners += f.corners_away ? +f.corners_away : 0;
                    }
                    total += 1;
                }
                return sum + numCorners;
            },
            0,
        );

        return corners / total;
    }

    get cornersAway(): number | string {
        let total = 0;
        let corners = 0;

        const reversed = this.data!.away.form.length > this.num ? this.data!.away.form.slice(1) : this.data!.away.form;

        if (every(reversed, { corners_home: null, corners_away: null })) {
            return 'Ndf';
        }

        corners = reduce(
            reversed,
            (sum: number, f) => {
                let numCorners = 0;
                if (f.code_state !== 'not_started' && (f.corners_home !== null || f.corners_away !== null)) {
                    const isHome = this.data!.away.name === f.home_team;


                    if (isHome) {
                        numCorners += f.corners_home ? +f.corners_home : 0;
                    } else {
                        numCorners += f.corners_away ? +f.corners_away : 0;
                    }
                    total += 1;
                }
                return sum + numCorners;
            },
            0,
        );

        return corners / total;
    }

    get cornersHomeHome(): number | string {
        let total = 0;
        let corners = 0;

        const reversed = this.data!.home.form.length > this.num ? this.data!.home.form.slice(1) : this.data!.home.form;

        if (every(reversed, { corners_home: null, corners_away: null })) {
            return 'Ndf';
        }

        corners = reduce(
            reversed,
            (sum: number, f) => {
                let numCorners = 0;
                if (f.code_state !== 'not_started' && f.corners_home !== null) {
                    const isHome = this.data!.home.name === f.home_team;

                    if (isHome) {
                        numCorners += f.corners_home ? +f.corners_home : 0;
                    }
                    total += 1;
                }
                return sum + numCorners;
            },
            0,
        );

        return corners / total;
    }

    get cornersAwayHome(): number | string {
        let total = 0;
        let corners = 0;

        const reversed = this.data!.away.form.length > this.num ? this.data!.away.form.slice(1) : this.data!.away.form;

        if (every(reversed, { corners_home: null, corners_away: null })) {
            return 'Ndf';
        }

        corners = reduce(
            reversed,
            (sum: number, f) => {
                let numCorners = 0;
                if (f.code_state !== 'not_started' && f.corners_home !== null) {
                    const isHome = this.data!.away.name === f.home_team;

                    if (isHome) {
                        numCorners += f.corners_home ? +f.corners_home : 0;
                    }
                    total += 1;
                }
                return sum + numCorners;
            },
            0,
        );

        return corners / total;
    }

    get homeHomeWidth(): number {
        return Math.round((+this.cornersHomeHome / (+this.cornersHomeHome + +this.cornersAwayHome)) * 100);
    }

    get awayHomeWidth(): number {
        return 100 - this.homeHomeWidth;
    }


    get cornersHomeAway(): number | string {
        let total = 0;
        let corners = 0;

        const reversed = this.data!.home.form.length > this.num ? this.data!.home.form.slice(1) : this.data!.home.form;

        if (every(reversed, { corners_home: null, corners_away: null })) {
            return 'Ndf';
        }

        corners = reduce(
            reversed,
            (sum: number, f) => {
                let numCorners = 0;
                if (f.code_state !== 'not_started' && f.corners_home !== null) {
                    const isHome = this.data!.home.name === f.home_team;

                    if (!isHome) {
                        numCorners += f.corners_away ? +f.corners_away : 0;
                    }
                    total += 1;
                }
                return sum + numCorners;
            },
            0,
        );

        return corners / total;
    }

    get cornersAwayAway(): number | string {
        let total = 0;
        let corners = 0;

        const reversed = this.data!.away.form.length > this.num ? this.data!.away.form.slice(1) : this.data!.away.form;

        if (every(reversed, { corners_home: null, corners_away: null })) {
            return 'Ndf';
        }

        corners = reduce(
            reversed,
            (sum: number, f) => {
                let numCorners = 0;
                if (f.code_state !== 'not_started' && f.corners_home !== null) {
                    const isHome = this.data!.away.name === f.home_team;

                    if (!isHome) {
                        numCorners += f.corners_away ? +f.corners_away : 0;
                    }
                    total += 1;
                }
                return sum + numCorners;
            },
            0,
        );

        return corners / total;
    }

    get homeAwayWidth(): number {
        return Math.round((+this.cornersHomeAway / (+this.cornersHomeAway + +this.cornersAwayAway)) * 100);
    }

    get awayAwayWidth(): number {
        return 100 - this.homeAwayWidth;
    }

    get homeWidth(): number {
        return Math.round((+this.cornersHome / (+this.cornersHome + +this.cornersAway)) * 100);
    }

    get awayWidth(): number {
        return 100 - this.homeWidth;
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.corners.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// TODO disable highlight module
// import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { WidgetsRoutingModule } from '@widgets/widgets-routing.module';
import { DemoComponent } from '@widgets/pages/demo/demo.component';
import { MatchWidgetComponent } from '@widgets/components/widget/match/match.component';
import { OverlayComponent } from '@widgets/components/overlay/overlay.component';
import { WidgetsComponent } from '@widgets/pages/widgets/widgets.component';

import { MatchXSWidgetComponent } from '@widgets/components/widget/match-xs/match-xs.component';
import { AvgGoalsComponent } from '@widgets/pages/widgets/avg-goals/avg-goals.component';
import { AvgGoalsWidgetComponent } from '@widgets/components/widget/avg-goals/avg-goals.component';
import { LastMatchesComponent } from '@widgets/pages/widgets/last-matches/last-matches.component';
import { HfFtComponent } from '@widgets/pages/widgets/hf-ft/hf-ft-component';
import { LastMatchesWidgetComponent } from '@widgets/components/widget/last-matches/last-matches.component';
import { HtFtWidgetComponent } from '@widgets/components/widget/ht-ft/ht-ft.component';

import { TopScorerWidgetComponent } from '@widgets/components/widget/top-scorer/top-scorer.component';
import { GoalScoredComponent } from '@widgets/pages/widgets/goal-scored/goal-scored.component';
import { GoalScoredWidgetComponent } from '@widgets/components/widget/goal-scored/goal-scored.component';
import { FormComponent } from '@widgets/pages/widgets/form/form.component';
import { FormParticipantsComponent } from '@widgets/components/stats/form/form.component';
import { FormWidgetComponent } from '@widgets/components/widget/form/form.component';
import { TwoWayComponent } from '@widgets/pages/widgets/2way/2way.component';
import { ThreeWayComponent } from '@widgets/pages/widgets/3way/3way.component';
import { TotalPointsComponent } from '@widgets/pages/widgets/total-points/total-points.component';
// eslint-disable-next-line max-len
import { WinProbabilityParticipantsComponent } from '@widgets/components/stats/win-probability/win-probability.component';
import { WinProbabilityWidgetComponent } from '@widgets/components/widget/win-probability/win-probability.component';
import { TwoWayWidgetComponent } from '@widgets/components/widget/2way/2way.component';
import { FistHalfComponent } from '@widgets/pages/widgets/1st-half/1st-half.component';
import { FirstHalfWidgetComponent } from '@widgets/components/widget/1st-half/1st-half.component';
import { BothTeamToScoreComponent } from '@widgets/pages/widgets/both-team-score/both-team-score.component';
import { BothTeamToScoreWidgetComponent } from '@widgets/components/widget/both-team-score/both-team-score.component';
import { CornersComponent } from '@widgets/pages/widgets/corners/corners.component';
import { CornersWidgetComponent } from '@widgets/components/widget/corners/corners.component';
import { UnderOverComponent } from '@widgets/pages/widgets/under-over/under-over.component';
import { UnderOverWidgetComponent } from '@widgets/components/widget/under-over/under-over.component';
import { CorrectScoreComponent } from '@widgets/pages/widgets/correct-score/correct-score.component';
import { CorrectScoreWidgetComponent } from '@widgets/components/widget/correct-score/correct-score.component';
import { DoubleChanceComponent } from '@widgets/pages/widgets/double-chance/double-chance.component';
import { DoubleChanceWidgetComponent } from '@widgets/components/widget/double-chance/double-chance.component';
import { TotalHockeyWidgetComponent } from '@widgets/components/widget/total-hockey/total.component';
import { TotalHockey3wWidgetComponent } from '@widgets/components/widget/total-hockey-3w/total-3w.component';
import { ScoringPeriodComponent } from '@widgets/pages/widgets/scoring-period/scoring-period.component';
import { ScoringPeriodWidgetComponent } from '@widgets/components/widget/scoring-period/scoring-period.component';
import { GamesWonComponent } from '@widgets/pages/widgets/games-won/games-won.component';
import { GamesWonWidgetComponent } from '@widgets/components/widget/games-won/games-won.component';
import { TotalSetsComponent } from '@widgets/pages/widgets/total-sets/total-sets.component';
import { TotalSetsWidgetComponent } from '@widgets/components/widget/total-sets/total-sets.component';
import { NumberOfSetsComponent } from '@widgets/pages/widgets/number-sets/number-sets.component';
import { NumOfsetsWidgetComponent } from '@widgets/components/widget/num-of-sets/num-of-sets.component';
import { SetsWonComponent } from '@widgets/pages/widgets/sets-won/sets-won.component';
import { SetsWonWidgetComponent } from '@widgets/components/widget/sets-won/sets-won.component';
import { BothToWinSetComponent } from '@widgets/pages/widgets/both-to-win-set/both-to-win-set.component';
import { NoSetWonComponent } from '@widgets/pages/widgets/no-sets-won/no-set-won-set.component';
import { BothToWinSetWidgetComponent } from '@widgets/components/widget/both-to-win-set/both-to-win-set.component';
import { NoSetsWonWidgetComponent } from '@widgets/components/widget/no-sets-won/no-sets-won.component';
import { DoubleResultComponent } from '@widgets/pages/widgets/double-result/double-result.component';
import { DoubleResultWidgetComponent } from '@widgets/components/widget/double-result/double-result.component';
import { BothTeamToScore2GoalsComponent }
    from '@widgets/pages/widgets/both-team-score-2goals/both-team-score-2goals.component';
import { BothTeamToScore2GoalsWidgetComponent }
    from '@widgets/components/widget/both-team-score-2goals/both-team-score-2goals.component';
import { BothTeamToScore3GoalsComponent }
    from '@widgets/pages/widgets/both-team-score-3goals/both-team-score-3goals.component';
import { BothTeamToScore3GoalsWidgetComponent }
    from '@widgets/components/widget/both-team-score-3goals/both-team-score-3goals.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatchWidgetDetailComponent } from '@widgets/components/widget/match-detail/match-detail.component';
import { ScoringHalfComponent } from '@widgets/pages/widgets/scoring-half/scoring-half.component';
import { ScoringHalfWidgetComponent } from '@widgets/components/widget/scoring-half/scoring-half.component';
import { WinHalfComponent } from '@widgets/pages/widgets/win-half/win-half.component';
import { WinHalfWidgetComponent } from '@widgets/components/widget/win-half/win-half.component';
import { TotalPointsWidgetComponent } from '@widgets/components/widget/total-points/total-points.component';
import { HighestScoringPeriodWidgetComponent }
    from '@widgets/components/widget/highest-scoring-quater/highest-scoring-quater.component';
import { HighestScoringHalfWidgetComponent }
    from '@widgets/components/widget/highest-scoring-half/highest-scoring-half.component';

import { HighestScoringQuaterComponent } from
    '@widgets/pages/widgets/highest-scoring-quater/highest-scoring-quater.component';

import { HighestScoringHalfComponent }
    from '@widgets/pages/widgets/highest-scoring-half/highest-scoring-half.component';
import { BasketWinPrctComponent } from '@widgets/pages/widgets/basket-win-prct/basket-win-prct.component';
import { BasketWinPrctWidgetComponent } from '@widgets/components/widget/basket-win-period/basket-win-prct.component';
import { Handicap2wayWidgetComponent } from '@widgets/components/widget/2way-handicap/2way-handicap.component';

import { ScoredEveryPeriodWidgetComponent }
    from '@widgets/components/widget/scored-every-period/scored-every-period.component';

import { CardsWidgetComponent } from '@widgets/components/widget/cards/cards.component';

import { GoalScorerStatsComponent } from '@widgets/pages/widgets/stats-goalscore/stats-goalscorer.component';

import { TotalGamesComponent } from './pages/widgets/total-games/total-games.component';


import { WillGameGoToShootoutComponent }
    from '@/modules/widgets/pages/widgets/will-go-to-shootout/will-go-to-shootout.component';

import { BestGoalscorerComponent } from '@/modules/widgets/pages/widgets/best-goalscore/best-goalscorer.component';
import { ComponentModule } from '@/components/component.module';
import { MatchFlashComponent } from '@/modules/widgets/pages/widgets/match-xs/match-xs.component';
import { Handicap2WayTennisComponent }
    from '@/modules/widgets/pages/widgets/2way-handicap-tennis/2way-handicap-tennis.component';
import { ScoredEveryPeriodComponent } from
    '@/modules/widgets/pages/widgets/scored-every-period/scored-every-period.component';
import { CardsComponent } from '@/modules/widgets/pages/widgets/cards/cards.component';
import { SpreadComponent } from '@/modules/widgets/pages/widgets/spread/spread.component';
import { WinEveryPeriodComponent } from '@/modules/widgets/pages/widgets/win-every-period/win-every-period.component';
import { DoubelResultPeriodsComponent }
    from '@/modules/widgets/pages/widgets/double-result-periods/double-result-periods.component';
import { WinEveryQuaterComponent } from '@/modules/widgets/pages/widgets/win-every-quater/win-every-quater.component';
import { ScoredEveryQuaterComponent }
    from '@/modules/widgets/pages/widgets/scored-every-quater/scored-every-quater.component';
import { WinBothHalvesComponent } from '@/modules/widgets/pages/widgets/win-both-halves/win-both-halves.component';
import { ScoredEveryHalfComponent }
    from '@/modules/widgets/pages/widgets/scored-every-half/scored-every-half.component';

@NgModule({
    declarations: [
        DemoComponent,
        MatchWidgetComponent,
        OverlayComponent,
        WidgetsComponent,
        MatchWidgetDetailComponent,
        MatchFlashComponent,
        MatchXSWidgetComponent,
        AvgGoalsComponent,
        AvgGoalsWidgetComponent,
        LastMatchesComponent,
        LastMatchesWidgetComponent,
        HtFtWidgetComponent,
        HfFtComponent,
        BestGoalscorerComponent,
        TopScorerWidgetComponent,
        GoalScoredComponent,
        GoalScoredWidgetComponent,
        Handicap2WayTennisComponent,
        Handicap2wayWidgetComponent,
        FormComponent,
        FormParticipantsComponent,
        FormWidgetComponent,
        ThreeWayComponent,
        WinProbabilityParticipantsComponent,
        WinProbabilityWidgetComponent,
        TwoWayComponent,
        TwoWayWidgetComponent,
        FistHalfComponent,
        FirstHalfWidgetComponent,
        BothTeamToScoreComponent,
        BothTeamToScoreWidgetComponent,
        CornersComponent,
        CornersWidgetComponent,
        UnderOverComponent,
        UnderOverWidgetComponent,
        CorrectScoreComponent,
        CorrectScoreWidgetComponent,
        DoubleChanceComponent,
        DoubleChanceWidgetComponent,
        TotalHockeyWidgetComponent,
        TotalHockey3wWidgetComponent,
        ScoringPeriodComponent,
        ScoringPeriodWidgetComponent,
        GamesWonComponent,
        GamesWonWidgetComponent,
        TotalSetsComponent,
        TotalSetsWidgetComponent,
        NumberOfSetsComponent,
        NumOfsetsWidgetComponent,
        SetsWonComponent,
        SetsWonWidgetComponent,
        BothToWinSetComponent,
        BothToWinSetWidgetComponent,
        NoSetWonComponent,
        NoSetsWonWidgetComponent,
        DoubleResultComponent,
        DoubleResultWidgetComponent,
        BothTeamToScore2GoalsComponent,
        BothTeamToScore2GoalsWidgetComponent,
        BothTeamToScore3GoalsComponent,
        BothTeamToScore3GoalsWidgetComponent,
        ScoringHalfComponent,
        ScoringHalfWidgetComponent,
        WinHalfComponent,
        WinHalfWidgetComponent,
        TotalPointsComponent,
        TotalPointsWidgetComponent,
        HighestScoringQuaterComponent,
        HighestScoringPeriodWidgetComponent,
        HighestScoringHalfComponent,
        HighestScoringHalfWidgetComponent,
        BasketWinPrctComponent,
        BasketWinPrctWidgetComponent,
        ScoredEveryPeriodComponent,
        ScoredEveryPeriodWidgetComponent,
        CardsComponent,
        CardsWidgetComponent,
        GoalScorerStatsComponent,
        SpreadComponent,
        WillGameGoToShootoutComponent,
        WinEveryPeriodComponent,
        TotalGamesComponent,
        DoubelResultPeriodsComponent,
        WinEveryQuaterComponent,
        ScoredEveryQuaterComponent,
        WinBothHalvesComponent,
        ScoredEveryHalfComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        WidgetsRoutingModule,
        // TODO disable highlight module
        // HighlightModule,
        MatTabsModule,
        ClipboardModule,
        ComponentModule,
        MatToolbarModule,
    ],
    providers: [
        // TODO disable highlight module
        // {
        //     provide: HIGHLIGHT_OPTIONS,
        //     useValue: {
        //         // eslint-disable-next-line import/no-extraneous-dependencies
        //         fullLibraryLoader: (): any => import('highlight.js'),
        //     },
        // },
    ],
})
export class WidgetsModule {}

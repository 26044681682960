/**
 *  Widgets - Assists (2way)
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { cloneDeep } from 'lodash-es';

import { MatchType } from '@/interfaces/league-table.interface';
import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-2way',
    templateUrl: './2way.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TwoWayComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public num: number;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub: Record<string, any>) => {
            this.num = sub.num;

            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    get streakHome(): number {
        let win = 0;

        let matches = 0;

        this.data!.home.form.forEach((f) => {
            if (f.status === 'win') {
                win += 1;
                matches += 1;
            }
            if (f.status === 'lose') {
                matches += 1;
            }
        });

        return Math.round((win / matches) * 100);
    }

    get streakAway(): number {
        let win = 0;

        let matches = 0;

        this.data!.away.form.forEach((f) => {
            if (f.status === 'win') {
                win += 1;
                matches += 1;
            }
            if (f.status === 'lose') {
                matches += 1;
                26250506;
            }
        });

        return Math.round((win / matches) * 100);
    }

    get homeWidth(): number {
        return Math.round((this.streakHome / (this.streakHome + this.streakAway)) * 100);
    }

    get awayWidth(): number {
        return 100 - this.homeWidth;
    }

    get homeLastWidth(): number {
        const [hW, hL] = this.lastHome.split('-');
        const [aW, aL] = this.lastAway.split('-');
        const total = +hW + +hL + +aW + +aL;
        const wins = +hW + +aL;
        return Math.round((wins / total) * 100);
    }

    get awayLastWidth(): number {
        return 100 - this.homeLastWidth;
    }

    get homewidthRanking(): number {
        return Math.round(
            (this.data!.home.tennis_rankings_single as number /
                ((this.data!.home.tennis_rankings_single as number) +
                (this.data!.away.tennis_rankings_single as number))) *
                100,
        );
    }

    get awaywidthRanking(): number {
        return 100 - this.homewidthRanking;
    }

    get lastHome(): string {
        let win = 0;
        let lose = 0;

        const data = cloneDeep(this.data!.home.form);
        const last = data.slice(-5);

        last.forEach((f) => {
            if (f.status === 'win') {
                win += 1;
            }
            if (f.status === 'lose') {
                lose += 1;
            }
        });

        return `${win}-${lose}`;
    }

    get lastAway(): string {
        let win = 0;
        let lose = 0;

        const data = cloneDeep(this.data!.away.form);
        const last = data.slice(-5);

        last.forEach((f) => {
            if (f.status === 'win') {
                win += 1;
            }
            if (f.status === 'lose') {
                lose += 1;
            }
        });

        return `${win}-${lose}`;
    }

    get rankingHome(): number | string {
        return this.data!.home.tennis_rankings_single || '-';
    }

    get rankingAway(): number | string {
        return this.data!.away.tennis_rankings_single || '-';
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.twoWay.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso as string, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),

                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.finished();
                },
            );
    }
}

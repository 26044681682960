import { EventEmitter, Injectable } from '@angular/core';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '@widgets/index';
import URL_CONFIG from '@widgets/config/url.config';
import { ClientInterface } from '@widgets/interfaces/client.interface';

import { AvgData, Form, GoalScored, LastMatches, TopScorer, TopScorerStats }
    from '@widgets/interfaces/widget.interface';

import { MyMatchesService } from '@/services/my-matches.service';

@Injectable({
    providedIn: 'root',
})
export class DemoService {
    public widgetLoader: EventEmitter<boolean> = new EventEmitter();

    public constructor(private http: HttpClient, private common: CommonService, public matches: MyMatchesService) {}

    /**
     * Convert source id to our match id
     */
    public sourceIdToId(idValFinal: number, source: string | null): Observable<number[]> {
        if (source === null) {
            return this.matches.brToId(idValFinal.toString());
        }
        return this.matches.sourceIdToId(idValFinal.toString(), source);
    }

    public getClient(codeName: string): Observable<ClientInterface> {
        const url = `${URL_CONFIG.api.clientInfo()}/${codeName}`;
        const options = {};

        return this.http.get<ClientInterface>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Goal scored
     */
    public goalScored(matchId: number, limit: number = 5, iso: string = 'en'): Observable<GoalScored> {
        const url = `${URL_CONFIG.api.goalScored(matchId, limit)}?lang=${iso}`;
        const options = {};

        return this.http.get<GoalScored>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Top Scorer
     */
    public topScorer(matchId: number, limit: number = 5, iso: string = 'en'): Observable<TopScorer> {
        const url = `${URL_CONFIG.api.topScorers(matchId, limit)}?lang=${iso}`;
        const options = {};

        return this.http.get<TopScorer>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Top Scorer Stats
     */
    public topScorerStats(seasonId: number, home: number, away: number, sportName: string, iso: string = 'en'):
    Observable<{home: TopScorerStats; away:TopScorerStats; }> {
        const url = `${URL_CONFIG.api.topScorersStats(seasonId, home, away)}?lang=${iso}&sportName=${sportName}`;
        const options = {};

        return this.http.get<{home: TopScorerStats; away:TopScorerStats; }>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Average goals
     */
    public avgGoals(p1: number, p2: number, iso: string = 'en', params: Record<string, any> = {}): Observable<AvgData> {
        const url = `${URL_CONFIG.api.avgGoals(p1, p2)}?lang=${iso}`;
        const options = { params };

        return this.http.get<AvgData>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Form
     */
    public form(p1: number, p2: number, iso: string | null = 'en', params: Record<string, any> = {}):
    Observable<Form> {
        const url = `${URL_CONFIG.api.form(p1, p2)}?lang=${iso}`;
        const options = { params };

        return this.http.get<Form>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Last matches
     */
    public lastMatches(p1: number, p2: number, limit: number = 5, iso: string = 'en'): Observable<LastMatches[]> {
        const url = `${URL_CONFIG.api.lastMatches(p1, p2, limit)}?lang=${iso}`;
        const options = {};

        return this.http.get<LastMatches[]>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }
}

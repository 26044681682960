<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-match-container d-flex flex-column">
        <h1 *ngIf="!noTitle" class="widget-title align-self-center">
            {{ 'web.widget_goal_scorer_title' | translate }}
        </h1>

        <div class="d-flex justify-content-around mt-md-4 mb-5 widget-container-page">
            <div class="widget-team d-flex flex-column" *ngIf="matchVal as m">
                <div class="widget-team__name text-center d-flex align-items-center mb-4">
                    <img
                        (error)="imageError($event)"
                        [attr.src]="m.participants[0]?.image | responsive: 'participant':'medium'"
                        [alt]="m.participants[0]?.name"
                        class="img-fluid app-cursor mr-1"
                    />
                    <div class="pl-2">{{ m.participants[0]?.name }}</div>
                </div>
                <div *ngIf="data.home?.length === 0" class="text-center">
                    <i class="fas fa-user-alt-slash"></i> {{ 'web.no_best_player' | translate }}
                </div>
                <div
                    *ngFor="let p of data.home; trackBy: trackByFn()"
                    class="widget-player d-flex justify-content-between align-items-center"
                >
                    <div class="img-circle img-circle--80 rounded-circle">
                        <img
                            (error)="imageError($event, 'avatar')"
                            [src]="'/assets/players/photos/' + p.id + '.png?t=' + p.modified + ''"
                            [alt]="p.full_name"
                        />
                    </div>

                    <div class="widget-player-name">{{ p.full_name }}</div>
                    <div class="widget-player-goal flex-shrink-0 text-center">{{ p.goals_for_team }}</div>
                </div>
            </div>
            <div class="widget-break-between"></div>
            <div class="widget-team d-flex flex-column" *ngIf="matchVal as m">
                <div class="widget-team__name justify-content-end d-flex align-items-center mb-4">
                    <div class="pr-2">{{ m.participants[1]?.name }}</div>
                    <img
                        (error)="imageError($event)"
                        [attr.src]="m.participants[1]?.image | responsive: 'participant':'medium'"
                        [alt]="m.participants[1]?.name"
                        class="img-fluid app-cursor mr-1"
                    />
                </div>
                <div *ngIf="data.away?.length === 0" class="text-center">
                    <i class="fas fa-user-alt-slash"></i> {{ 'web.no_best_player' | translate }}
                </div>
                <div
                    *ngFor="let p of data.away; trackBy: trackByFn()"
                    class="widget-player d-flex justify-content-between align-items-center"
                >
                    <div class="widget-player-goal flex-shrink-0 text-center">{{ p.goals_for_team }}</div>
                    <div class="widget-player-name">{{ p.full_name }}</div>
                    <div class="img-circle img-circle--80 rounded-circle">
                        <img
                            (error)="imageError($event, 'avatar')"
                            [src]="'/assets/players/photos/' + p.id + '.png?t=' + p.modified + ''"
                            [alt]="p.full_name"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <img [src]="'/img/' + client.getName() + '.png'" [alt]="client.getName()" class="widget-match-logo" />
        </div>
    </div>
</ng-container>

/**
 *  Widgets - Last matches
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { LastMatches } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';
import APP_CONFIG from '@config/app.config';

import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';


@Component({
    selector: 'widgets-last-matches',
    templateUrl: './last-matches.component.html',
    styleUrls: ['./styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LastMatchesComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: LastMatches[] | null = null;

    public matchObj: MatchData;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    /**
     * Check if home team is winner
     * @return {boolean}
     */
    public isWinnerHome(data: LastMatches): boolean {
        return +data.score.home_team > +data.score.away_team;
    }

    /**
     * Check if away team is winner
     * @return {boolean}
     */
    public isWinnerAway(data: LastMatches): boolean {
        return +data.score.home_team < +data.score.away_team;
    }

    private load(): void {
        const iso: string = this.lang.getLangSnapshot() ?? APP_CONFIG.defaultLang;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.lastMatches.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<any> => {
                        this.matchObj = match;
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);

                        return this.demo.lastMatches(p1, p2, this.num, iso);
                    },
                ),
                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                },
                () => {},
                () => {
                    this.finished();
                },
            );
    }
}

/**
 *  Widgets - Match flash
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { GoalScored } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { ClientService } from '@/services/client.service';
import { MatchData } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-goal-scored',
    templateUrl: './goal-scored.component.html',
    styleUrls: ['./styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GoalScoredComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];
    public matchVal: MatchData;

    private matchId: number;

    private subscriber: Subscription;

    public data: GoalScored | null = null;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                tap((match: MatchData) => {
                    this.matchVal = match;
                }),
                filter((match: MatchData) => CONFIG.goalScored.availSports.includes(match.sport_code_name)),
                mergeMap((match: MatchData): Observable<GoalScored> => this.demo.goalScored(match.id)),
                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )
            .subscribe(
                (data) => {
                    this.data = data;

                    this.finished();
                },
                () => {},
                () => {
                    this.finished();
                },
            );
    }
}

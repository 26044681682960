/**
 *  Widgets - Both team to score
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';
import SPORT_CONFIG from '@config/sport.config';

import { ClientService } from '@/services/client.service';
import { CodeState, MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';
import { MatchType } from '@/interfaces/league-table.interface';


@Component({
    selector: 'widgets-will-go-to-shootout-period',
    templateUrl: './will-go-to-shootout.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WillGameGoToShootoutComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    [prop: string]: any;

    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public sportName: string;

    public num = 10;


    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    get isHockey(): boolean {
        return SPORT_CONFIG.isHockey(this.sportName);
    }


    get shootoutHome(): number {
        let shootout = 0;
        let count = 0;

        const reversed = this.data!.home.form.length > this.num ? this.data!.home.form.slice(1) : this.data!.home.form;

        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && f.code_state) {
                count += 1;


                if (f.code_state === CodeState.AP) {
                    shootout += 1;
                }
            }
        });

        return Math.round((shootout / count) * 100);
    }

    get shootoutAway(): number {
        let shootout = 0;
        let count = 0;

        const reversed = this.data!.away.form.length > this.num ? this.data!.away.form.slice(1) : this.data!.away.form;

        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && f.code_state) {
                count += 1;


                if (f.code_state === CodeState.AP) {
                    shootout += 1;
                }
            }
        });

        return Math.round((shootout / count) * 100);
    }


    get homeWidth2(): number {
        return Math.round((this.shootoutHome / (this.shootoutHome + this.shootoutAway)) * 100);
    }

    get awayWidth2(): number {
        return 100 - this.homeWidth2;
    }

    get aetHome(): number {
        let shootout = 0;
        let count = 0;

        const reversed = this.data!.home.form.length > this.num ? this.data!.home.form.slice(1) : this.data!.home.form;

        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && f.code_state) {
                count += 1;


                if (f.code_state === CodeState.AET) {
                    shootout += 1;
                }
            }
        });

        return Math.round((shootout / count) * 100);
    }

    get aetAway(): number {
        let shootout = 0;
        let count = 0;

        const reversed = this.data!.away.form.length > this.num ? this.data!.away.form.slice(1) : this.data!.away.form;

        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && f.code_state) {
                count += 1;


                if (f.code_state === CodeState.AET) {
                    shootout += 1;
                }
            }
        });

        return Math.round((shootout / count) * 100);
    }


    get aetomeWidth2(): number {
        return Math.round((this.aetHome / (this.aetHome + this.aetAway)) * 100);
    }

    get aetawayWidth2(): number {
        return 100 - this.aethomeWidth2;
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                tap((match: MatchData) => {
                    this.sportName = match.sport_code_name;
                }),
                filter((match: MatchData) => CONFIG.willGameGoToShootout.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

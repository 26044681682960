<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-match-container d-flex flex-column">
        <h1 *ngIf="!noTitle" class="avg-goals-title align-self-center">
            {{ 'web.widget_goal_scored_title' | translate }}
        </h1>
        <div class="avg-goals-team d-flex flex-column" *ngIf="data?.home as p">
            <div class="d-flex avg-goals-team__container">
                <div class="avg-goals-team__name d-flex align-items-center">
                    <img
                        (error)="imageError($event)"
                        [attr.src]="(matchVal?.participants)[0].image | responsive: 'participant':'medium'"
                        [alt]="(matchVal?.participants)[0].name"
                        class="img-fluid app-cursor mr-1"
                    />
                    <div>{{ (matchVal?.participants)[0].name }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-between avg-goals-team__container2">
                <div
                    class="avg-goals-team__score text-center"
                    *ngFor="let match of p; trackBy: trackByFn; first as isFirst; index as i"
                    [class.avg-goals-team__score--last]="isFirst"
                >
                    <div
                        class="avg-goals-team__score-number avg-goals-team__score-number--yes"
                        [class.avg-goals-team__score-number--yes]="match.yes"
                        [class.avg-goals-team__score-number--no]="!match.yes"
                    >
                        <ng-container *ngIf="match.yes; else no">{{ 'web.yes' | translate }}</ng-container>
                        <ng-template #no>{{ 'web.no' | translate }}</ng-template>
                    </div>
                    <div class="avg-goals-team__score-text">
                        <ng-container *ngIf="isFirst; else notFirst">
                            {{ 'web.last_match' | translate }}
                        </ng-container>
                        <ng-template #notFirst>
                            {{ i + 1 }}
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <img [src]="'/img/' + client.getName() + '.png'" [alt]="client.getName()" class="widget-match-logo" />
        </div>

        <div class="avg-goals-team d-flex flex-column avg-goals-team--second" *ngIf="data?.away as p">
            <div class="d-flex avg-goals-team__container">
                <div class="avg-goals-team__name d-flex align-items-center">
                    <img
                        (error)="imageError($event)"
                        [attr.src]="(matchVal?.participants)[1].image | responsive: 'participant':'medium'"
                        [alt]="(matchVal?.participants)[1].name"
                        class="img-fluid app-cursor mr-1"
                    />
                    <div>{{ (matchVal?.participants)[1].name }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-between avg-goals-team__container2">
                <div
                    class="avg-goals-team__score text-center"
                    *ngFor="let match of p; trackBy: trackByFn; first as isFirst; index as i"
                    [class.avg-goals-team__score--last]="isFirst"
                >
                    <div
                        class="avg-goals-team__score-number avg-goals-team__score-number--yes"
                        [class.avg-goals-team__score-number--yes]="match.yes"
                        [class.avg-goals-team__score-number--no]="!match.yes"
                    >
                        <ng-container *ngIf="match.yes; else no">{{ 'web.yes' | translate }}</ng-container>
                        <ng-template #no>{{ 'web.no' | translate }}</ng-template>
                    </div>
                    <div class="avg-goals-team__score-text">
                        <ng-container *ngIf="isFirst; else notFirst">
                            {{ 'web.last_match' | translate }}
                        </ng-container>
                        <ng-template #notFirst>
                            {{ i + 1 }}
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

/**
 *  Widgets parent page
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '@widgets/pages/base.component';

import { DemoService } from '@widgets/services/demo.service';

import { TranslationService } from '@/services/translation.service';

@Component({
    selector: 'widgets-page',
    templateUrl: './widgets.component.html',
})
export class WidgetsComponent extends BaseComponent implements OnInit {
    public loaded = false;

    public constructor(private demo: DemoService) {
        super(demo);
    }

    public ngOnInit(): void {
        TranslationService.isLoaded = false;

        this.demo.widgetLoader.subscribe((val) => {
            if (val) {
                this.loaded = true;
            }
        });
    }
}

/* eslint-disable camelcase */
/**
 *  Widgets - Under/Over, Total
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';

import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';

import { propertyMetada } from '@services/decorators/propety.decorator';

import { LangService } from '@/services/lang.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';

import { ClientService } from '@/services/client.service';


import { MatchType } from '@/interfaces/league-table.interface';


@Component({
    selector: 'widgets-under-over',
    templateUrl: './under-over.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UnderOverComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public type: string = 'default';

    @propertyMetada({
        description: 'Sport configuration',
        type: 'object',
    })
    private restrictSports:Record<string, string[]>;

    public equal: boolean;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.restrictSports = sub.restrictSports;
            this.num = sub.num;
            this.equal = sub.equal ?? false;
            this.type = sub.type ?? 'default';
            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    public getPrct(side: 'home' | 'away', num: number, type: 'over' | 'under' | 'equal' = 'over'): number {
        if (type === 'under' && !this.equal) {
            return 100 - this.getPrct(side, num);
        } if (type === 'under' && this.equal) {
            return 100 - this.getPrct(side, num) - this.getPrct(side, num, 'equal');
        }

        let over = 0;
        let total = 0;
        let equal = 0;

        const reversed = this.data![side].form.length > this.num
            ? this.data![side].form.slice(1) : this.data![side].form;
        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && f.score_normal_time) {
                const [home, away] = f.score_normal_time.split(':');
                const sum = +home + +away;

                if (sum > num) {
                    over += 1;
                } else if (sum === num) {
                    equal += 1;
                }
                total += 1;
            }
        });

        if (type === 'equal') {
            return Math.round((equal / total) * 100);
        }
        return Math.round((over / total) * 100);
    }

    public width(side: 'home' | 'away', num: number, type: 'over' | 'under' | 'equal' = 'over'): number {
        const secondSide = side === 'home' ? 'away' : 'home';
        const main = this.getPrct(side, num, type);
        const second = this.getPrct(secondSide, num, type);
        const total = +main + +second;

        return Math.round((main / total) * 100);
    }

    get uoData(): number[] {
        const sportName = this.data?.home.form[0].sport_name;
        // @ts-ignore
        return this.restrictSports.uo[sportName] || [];
    }

    get avgHome(): number {
        let goals = 0;
        let total = 0;

        const reversed = this.data!.home.form.length > this.num ? this.data!.home.form.slice(1) : this.data!.home.form;
        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && f.score_normal_time) {
                const [home, away] = f.score_normal_time.split(':');
                const isHome = this.data!.home.name === f.home_team;

                if (isHome) {
                    goals += +home;
                } else {
                    goals += +away;
                }
                total += 1;
            }
        });

        return goals / total;
    }

    get avgAway(): number {
        let goals = 0;
        let total = 0;

        const reversed = this.data!.away.form.length > this.num ? this.data!.away.form.slice(1) : this.data!.away.form;
        reversed.forEach((f) => {
            if (f.code_state !== 'not_started' && f.score_normal_time) {
                const [home, away] = f.score_normal_time.split(':');
                const isHome = this.data!.away.name === f.home_team;

                if (isHome) {
                    goals += +home;
                } else {
                    goals += +away;
                }
                total += 1;
            }
        });

        return goals / total;
    }

    get avgTotal(): number {
        return this.avgHome + this.avgAway;
    }

    get homeWidth(): number {
        return 50;
    }

    get awayWidth(): number {
        return 100 - this.homeWidth;
    }

    get rankingHome(): number | string {
        return this.data!.home.tennis_rankings_single || '-';
    }

    get rankingAway(): number | string {
        return this.data!.away.tennis_rankings_single || '-';
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => this.restrictSports?.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso as string, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

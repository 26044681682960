<app-widgets-overlay *ngIf="loadedError" type="error_match"></app-widgets-overlay>
<app-widgets-overlay *ngIf="loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="widget-title align-self-center">{{ 'web.widget_2way_title' | translate }}</h1>
            <div class="d-flex">
                <div class="widget-team mt-1 d-flex flex-column" *ngIf="data?.home as p" data-cy="team">
                    <div class="widget-team__name d-flex align-items-center">
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor mr-1"
                        />
                        <div>{{ p.name }}</div>
                    </div>
                </div>

                <div
                    class="widget-team mt-1 d-flex flex-column widget-team--second"
                    *ngIf="data?.away as p"
                    data-cy="team"
                >
                    <div class="widget-team__name d-flex align-items-center justify-content-end">
                        <div>{{ p.name }}</div>
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor ml-1"
                        />
                    </div>
                </div>
            </div>

            <!-- 52 week win streak -->
            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--home" data-cy="streakHome">
                        {{ streakHome }}%
                    </div>
                    <div class="widget-form-participant-text align-self-end">
                        {{ 'web.participant_51_win_streak' | translate }}
                    </div>
                    <div class="widget-form-participant widget-form-participant--away" data-cy="streakAway">
                        {{ streakAway }}%
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home"
                        [style.width.%]="homeWidth"
                    >
                        {{ data?.home?.name }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away"
                        [style.width.%]="awayWidth"
                    >
                        {{ data?.away?.name }}
                    </div>
                </div>
            </div>

            <!-- Last 5 matches -->
            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--home" data-cy="lastHome">
                        {{ lastHome }}
                    </div>
                    <div class="widget-form-participant-text align-self-end">
                        {{ 'web.participant_last_5_matches' | translate }}
                    </div>
                    <div class="widget-form-participant widget-form-participant--away" data-cy="lastAway">
                        {{ lastAway }}
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home"
                        [style.width.%]="homeLastWidth"
                    ></div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away"
                        [style.width.%]="awayLastWidth"
                    ></div>
                </div>
            </div>

            <!-- Rank -->
            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--home" data-cy="rankingHome">
                        {{ rankingHome }}
                    </div>
                    <div class="widget-form-participant-text align-self-end">
                        {{ 'web.participant_ranking' | translate }}
                    </div>
                    <div class="widget-form-participant widget-form-participant--away" data-cy="rankingAway">
                        {{ rankingAway }}
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home"
                        [style.width.%]="awaywidthRanking"
                    ></div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away"
                        [style.width.%]="homewidthRanking"
                    ></div>
                </div>
            </div>

            <div class="widget-logo d-flex justify-content-center mt-4">
                <img
                    [src]="'/img/' + client.getName() + '.png'"
                    [alt]="client.getName()"
                    data-cy="logo"
                    class="widget-logo-client"
                />
            </div>
        </div>
    </div>
</ng-container>

/* eslint-disable max-len */
/**
 *  Widgets - Stats scorers
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2025 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import {
    TopScorerStats } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import SPORT_CONFIG from '@config/sport.config';

import { maxBy } from 'lodash-es';

import { ClientService } from '@/services/client.service';
import { MatchData } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';


@Component({
    selector: 'widgets-stats-goalscore',
    templateUrl: './stats-goalscorer.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GoalScorerStatsComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matchVal: MatchData;

    private matchId: number;

    private subscriber: Subscription;

    public data: {home: TopScorerStats; away:TopScorerStats; } | null = null;

    public limit: number = 5;

    #dataError: boolean = false;


    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.paramMap.subscribe((params) => {
            if (params.has('limit')) {
                this.limit = +params.get('limit')!;
            }

            this.router.data.subscribe((sub) => {
                if (sub.br && sub.br.length > 0) {
                    [this.matchId] = sub.br;
                } else {
                    this.finished();
                }

                if (this.matchId) {
                    this.load();
                } else {
                    this.error();
                }
            });
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
    }

    get home(): Record<string, any>[] {
        return this.data?.home.players ?? [];
    }

    get away(): Record<string, any>[] {
        return this.data?.away.players ?? [];
    }

    get noData(): boolean {
        return this.home.length === 0 || this.away.length === 0 || this.#dataError;
    }

    get isHockey(): boolean {
        return SPORT_CONFIG.isHockey(this.matchVal.sport_code_name);
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.statsScorer.availSports.includes(match.sport_code_name)),
                tap((match: MatchData) => {
                    this.matchVal = match;
                }),
                mergeMap(
                    // eslint-disable-next-line max-len
                    (match: MatchData): Observable<{home: TopScorerStats; away:TopScorerStats; }> => this.demo.topScorerStats(match.season_id!, match.participants[0]!.id, match.participants[1]!.id, match.sport_code_name, iso),
                ),

                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;


                    if (this.data?.home?.players) {
                        this.data.home.players = this.data.home.players.slice(0, this.limit);

                        if (!this.isHockey) { this.#dataError = this.data.home.players.every(v => !v.matches_played || v.matches_played === 0); }
                    }
                    if (this.data?.away?.players) {
                        this.data.away.players = this.data.away.players.slice(0, this.limit);

                        if (!this.isHockey) { this.#dataError = this.#dataError || this.data.home.players.every(v => !v.matches_played || v.matches_played === 0); }
                    }

                    if (this.#dataError) {
                        return;
                    }

                    let attrData = 'goal_average';
                    if (SPORT_CONFIG.isHockey(this.matchVal.sport_code_name)) {
                        attrData = 'points';
                    }

                    if (this.data?.home?.players) {
                        let max = maxBy(this.data?.home.players, (v:any) => v[attrData]) as Record<string, any>;
                        let homeMax = max[attrData] ?? 0;

                        if (SPORT_CONFIG.isHockey(this.matchVal.sport_code_name)) {
                            max = maxBy(this.data?.home.players, (v:any) => v.goals_scored / v[attrData]) as Record<string, any>;
                            homeMax = max.goals_scored / max[attrData];
                        }


                        this.data.home.players = this.data.home.players.map((v:any) => {
                            if (SPORT_CONFIG.isHockey(this.matchVal.sport_code_name)) {
                                const mathData = v.goals_scored / v[attrData];
                                const prct = (mathData / homeMax) * 100;
                                v.width = (prct / 100) * 80;
                            } else {
                                const prct = (v[attrData] / homeMax) * 100;
                                v.width = (prct / 100) * 80;
                            }
                            return v;
                        });
                    }

                    if (this.data?.away?.players) {
                        let max = maxBy(this.data?.away.players, (v:any) => v[attrData]) as Record<string, any>;
                        let homeMax = max[attrData] ?? 0;

                        if (SPORT_CONFIG.isHockey(this.matchVal.sport_code_name)) {
                            max = maxBy(this.data?.away.players, (v:any) => v.goals_scored / v[attrData]) as Record<string, any>;
                            homeMax = max.goals_scored / max[attrData];
                        }

                        this.data.away.players = this.data.away.players.map((v:any) => {
                            if (SPORT_CONFIG.isHockey(this.matchVal.sport_code_name)) {
                                const mathData = v.goals_scored / v[attrData];
                                const prct = (mathData / homeMax) * 100;
                                v.width = (prct / 100) * 80;
                            } else {
                                const prct = (v[attrData] / homeMax) * 100;
                                v.width = (prct / 100) * 80;
                            }
                            return v;
                        });
                    }
                    this.finished();
                },
                () => {},
                () => {
                    this.finished();
                },
            );
    }
}

/**
 *  URL config
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

const conf = {
    base: 'api/widgets',
    api: {
        goalScored(id: number, limit: number = 5): string {
            return `${conf.base}/get-goal-scored/${id}/${limit}`;
        },
        topScorers(id: number, limit: number = 5): string {
            return `${conf.base}/get-top-scorer/${id}/${limit}`;
        },
        topScorersStats(id: number, home: number, away: number): string {
            return `${conf.base}/player-stats/${id}/${home}/${away}`;
        },
        lastMatches(p1: number, p2: number, limit: number): string {
            return `${conf.base}/get-last-matches/${p1}/${p2}/${limit}`;
        },
        avgGoals(p1: number, p2: number): string {
            return `${conf.base}/get-h2h-avg-goals/${p1}/${p2}`;
        },
        form(p1: number, p2: number): string {
            return `${conf.base}/get-form/${p1}/${p2}`;
        },
        clientInfo(): string {
            return `${conf.base}/get-client`;
        },
    },
};

export default conf;

/* eslint-disable camelcase */
/**
 *  Widgets - Scored every quater Am football
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2025 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';


import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';
import { MatchType } from '@/interfaces/league-table.interface';


@Component({
    selector: 'widgets-scored-every-half',
    templateUrl: './scored-every-half.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ScoredEveryHalfComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public sportName: string;

    public periods: string[] = [
        'score_period_1',
        'score_period_2',
        'score_period_3',
        'score_period_4',
    ];

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;

            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }


    public lead(side: 'home' | 'away'): number {
        let total = 0;
        let count = 0;

        const reversed = this.data![side].form.length > this.num
            ? this.data![side].form.slice(1) : this.data![side].form;

        reversed.forEach((f) => {
            if (f.code_state !== 'not_started') {
                const [home1, away1] = (f.score_period_1 as string).split(':');
                const [home2, away2] = (f.score_period_2 as string).split(':');
                const [home3, away3] = (f.score_period_3 as string).split(':');
                const [home4, away4] = (f.score_period_4 as string).split(':');

                const isHome = this.data![side].name === f.home_team;


                if (home1 && home2 && away1 && away2 && home3 && home4 && away3 && away4) {
                    const firstHalfHome = +home1 + +home2;
                    const secondHalfHome = +home3 + +home4;
                    const firstHalfAway = +away1 + +away2;
                    const secondHalfAway = +away3 + +away4;
                    if (isHome && firstHalfHome > 0 && secondHalfHome > 0) {
                        total += 1;
                    }

                    if (isHome && firstHalfAway > 0 && secondHalfAway > 0) {
                        total += 1;
                    }
                    count += 1;
                }
            }
        });

        return Math.round((total / count) * 100);
    }


    public width(side: 'home' | 'away'): number {
        const val = this.lead(side);
        const oposite = side === 'home' ? this.lead('away') : this.lead('home');
        return Math.round((val / (val + oposite)) * 100);
    }


    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                tap((match: MatchData) => {
                    this.sportName = match.sport_code_name;
                }),
                filter((match: MatchData) => CONFIG.scoredEveryHalf.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}

<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="widget-title align-self-center">
                {{ 'web.widget_total_sets_head' | translate }}
            </h1>
            <div class="d-flex">
                <div class="widget-team mt-1 d-flex flex-column" *ngIf="data?.home as p" data-cy="team">
                    <div class="widget-team__name d-flex align-items-center">
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor mr-1"
                        />
                        <div>{{ p.name }}</div>
                    </div>
                </div>

                <div
                    class="widget-team mt-1 d-flex flex-column widget-team--second"
                    *ngIf="data?.away as p"
                    data-cy="team"
                >
                    <div class="widget-team__name d-flex align-items-center justify-content-end">
                        <div>{{ p.name }}</div>
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor ml-1"
                        />
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class=" ">&nbsp;</div>
                    <div class="widget-form-participant-text align-self-end">
                        {{ 'web.total_sets_combinied' | translate }}
                    </div>
                    <div class=" ">&nbsp;</div>
                </div>

                <div class="d-flex align-content-stretch widget-form-participant--big">
                    <div class="widget-form-participant-bar widget-form-participant-bar--home" [style.width.%]="30">
                        <span class="widget-form-participant-bar--big2" data-cy="avg-home">{{
                            totalHome | number: '1.0-1'
                        }}</span>
                        <br />
                        {{ data?.home?.name }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--uni widget-form-participant-bar--big text-center"
                        data-cy="avg-total"
                    >
                        {{ avgTotal | number: '1.0-2' }}
                    </div>
                    <div class="widget-form-participant-bar widget-form-participant-bar--away" [style.width.%]="30">
                        <span class="widget-form-participant-bar--big2" data-cy="avg-away">{{
                            totalAway | number: '1.0-1'
                        }}</span>
                        <br />
                        {{ data?.away?.name }}
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--home" data-cy="avg-home2">
                        <ng-container>{{ totalHome | number: '1.0-1' }}</ng-container>
                    </div>
                    <div class="widget-form-participant-text align-self-end">
                        {{ 'web.sets_playes_per_match' | translate }}
                    </div>
                    <div class="widget-form-participant widget-form-participant--away" data-cy="avg-away2">
                        <ng-container>{{ totalAway | number: '1.0-1' }}</ng-container>
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home"
                        [style.width.%]="homeWidth"
                    >
                        {{ data?.home?.name }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away"
                        [style.width.%]="awayWidth"
                    >
                        {{ data?.away?.name }}
                    </div>
                </div>
            </div>
            <div class="w-100 text-center widget-form-participant-text app-word-nowrap">
                {{ 'web.num_of_matches' | translate }} ({{ num }})
            </div>
            <div class="widget-logo d-flex justify-content-center mt-4">
                <img
                    [src]="'/img/' + client.getName() + '.png'"
                    [alt]="client.getName()"
                    data-cy="logo"
                    class="widget-logo-client"
                />
            </div>
        </div>
    </div>
</ng-container>

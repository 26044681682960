<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<div *ngIf="!loaded" class="widget-page-loader"></div>
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="widget-title align-self-center">
                {{ 'web.widget_correct_score' | translate }}
            </h1>

            <div class="widget-win-probability d-flex">
                <div class="widget-win-probability__main widget-win-probability--uni1" data-cy="cs-sum-1">
                    <span class="widget-win-probability-emph">{{ sort[0]!['prct'] | number: '1.0-0' }}%</span>
                    <br />
                    {{ sort[0]!['score'] }}
                </div>
                <div class="widget-win-probability__right d-flex flex-column flex-grow-1">
                    <div
                        class="widget-win-probability--uni2 d-flex justify-content-between"
                        style="height: 60%"
                        data-cy="cs-sum-2"
                    >
                        <span class="widget-win-probability-emph2">{{ sort[1]!['prct'] | number: '1.0-0' }}%</span>
                        <span> {{ sort[1]!['score'] }}</span>
                    </div>
                    <div
                        class="widget-win-probability--uni3 flex-grow-1 d-flex justify-content-between"
                        data-cy="cs-sum-3"
                    >
                        <span class="widget-win-probability-emph3">{{ sort[2]!['prct'] | number: '1.0-0' }}%</span>
                        <span> {{ sort[2]!['score'] }} </span>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--home" data-cy="csHome1">
                        {{ home[0]!['prct'] }}%
                    </div>
                    <div class="widget-form-participant widget-form-participant--home2" data-cy="csHome2">
                        {{ home[1]!['prct'] }}%
                    </div>
                    <div class="widget-form-participant widget-form-participant--home3" data-cy="csHome3">
                        {{ home[2]!['prct'] }}%
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home text-center"
                        [style.width.%]="34"
                    >
                        {{ home[0]!['score'] }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home2 text-center"
                        [style.width.%]="32"
                    >
                        {{ home[1]!['score'] }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home3 text-center"
                        [style.width.%]="34"
                    >
                        {{ home[2]!['score'] }}
                    </div>
                </div>
                <div *ngIf="data?.home as p" class="text-center widget-form-participant--home f-14">
                    {{ p.name }}
                </div>
            </div>

            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--away" data-cy="csAway1">
                        {{ away[0]!['prct'] }}%
                    </div>
                    <div class="widget-form-participant widget-form-participant--away2" data-cy="csAway2">
                        {{ away[1]!['prct'] }}%
                    </div>
                    <div class="widget-form-participant widget-form-participant--away3" data-cy="csAway3">
                        {{ away[2]!['prct'] }}%
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away text-center"
                        [style.width.%]="34"
                    >
                        {{ away[0]!['score'] }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away2 text-center"
                        [style.width.%]="32"
                    >
                        {{ away[1]!['score'] }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away3 text-center"
                        [style.width.%]="34"
                    >
                        {{ away[2]!['score'] }}
                    </div>
                </div>
                <div *ngIf="data?.away as p" class="text-center widget-form-participant--away f-14">
                    {{ p.name }}
                </div>
            </div>
            <div class="w-100 text-center widget-form-participant-text app-word-nowrap">
                {{ 'web.num_of_matches' | translate }} ({{ num }})
            </div>
            <div class="widget-logo d-flex justify-content-center mt-4">
                <img
                    [src]="'/img/' + client.getName() + '.png'"
                    [alt]="client.getName()"
                    data-cy="logo"
                    class="widget-logo-client"
                />
            </div>
        </div>
    </div>
</ng-container>

/**
 *  Form component both participants
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { Component, Input } from '@angular/core';
import { LastForm } from '@/modules/widgets/interfaces/widget.interface';

@Component({
    selector: 'widget-form-participants',
    templateUrl: './form.component.html',
})
export class FormParticipantsComponent {
    @Input() formHome: LastForm[];
    @Input() formAway: LastForm[];

    public home: number = 100;
    public away: number = 100;

    get homeWidth(): number {
        const total = this.home + this.away;
        const result = (this.home / total) * 100;
        return Math.round(result);
    }

    get awayWidth(): number {
        return 100 - this.homeWidth;
    }
}

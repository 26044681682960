<div class="widget-win-probability d-flex">
    <div class="widget-win-probability__main  {{ class1 }}" data-cy="widget-probability-item">
        <span class="widget-win-probability-emph">{{ sort[0] | number: '1.0-0' }}%</span>
        <br />
        {{ select1 }}
    </div>
    <div class="widget-win-probability__right d-flex flex-column flex-grow-1 ">
        <div class="{{ class2 }} d-flex justify-content-between" style="height:60%;" data-cy="widget-probability-item">
            <span class="widget-win-probability-emph2">{{ sort[1] | number: '1.0-0' }}%</span>
            <span> {{ select2 }}</span>
        </div>
        <div class="{{ class3 }} flex-grow-1 d-flex justify-content-between" data-cy="widget-probability-item">
            <span class="widget-win-probability-emph3">{{ sort[2] | number: '1.0-0' }}%</span>
            <span> {{ select3 }} </span>
        </div>
    </div>
</div>

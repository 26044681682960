<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay
    *ngIf="(!loadedError && loaded && !data) || (loaded && noData)"
    type="empty-data"
></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="widget-title align-self-center">
                {{ 'web.widget_goal_scorer_stats_title' | translate }}
            </h1>

            <div class="d-flex" *ngIf="matchVal as m">
                <div class="widget-team mt-1 d-flex flex-column" *ngIf="data?.home as p" data-cy="team">
                    <div class="widget-team__name d-flex align-items-center">
                        <img
                            (error)="imageError($event)"
                            [attr.src]="m.participants[0]?.image | responsive : 'participant' : 'medium'"
                            [alt]="m.participants[0]?.name"
                            class="img-fluid app-cursor mr-1"
                        />
                        <div>{{ m.participants[0]?.name }}</div>
                    </div>
                </div>

                <div
                    class="widget-team mt-1 d-flex flex-column widget-team--second"
                    *ngIf="data?.away as p"
                    data-cy="team"
                >
                    <div class="widget-team__name d-flex align-items-center justify-content-end">
                        <div>{{ m.participants[1]?.name }}</div>
                        <img
                            (error)="imageError($event)"
                            [attr.src]="m.participants[1]?.image | responsive : 'participant' : 'medium'"
                            [alt]="m.participants[1]?.name"
                            class="img-fluid app-cursor ml-1"
                        />
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant-text align-self-end mb-2">
                        <span class="font-weight-bold"> {{ matchVal.participants[0]?.name }} </span>
                    </div>
                </div>
                <ng-container *ngFor="let pl of home | slice : 0 : limit; trackBy: trackByIndex">
                    <div class="d-flex">
                        <div class="widget-form-participant-bar widget-form-participant-bar--uni" [style.width.%]="20">
                            <span>
                                {{ pl.goals_scored }} / <span *ngIf="!isHockey">{{ pl.matches_played }}</span>
                                <span *ngIf="isHockey">{{ pl.points }}</span> <br />
                            </span>
                        </div>
                        <div
                            class="widget-form-participant-bar widget-form-participant-bar--away"
                            [style.width.%]="pl.width"
                        >
                            <span *ngIf="!isHockey"> {{ pl.goal_average }}</span>
                            <span *ngIf="isHockey"> {{ (pl.goals_scored / pl.points) * 100 | number : '1.1-1' }}%</span>
                        </div>
                    </div>
                    <div class="widget-form-participant-text mb-3">
                        {{ pl.player_name }}
                    </div>
                </ng-container>
            </div>

            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant-text align-self-end mb-2">
                        <span class="font-weight-bold"> {{ matchVal.participants[1]?.name }} </span>
                    </div>
                </div>
                <ng-container *ngFor="let pl of away | slice : 0 : limit; trackBy: trackByIndex">
                    <div class="d-flex">
                        <div class="widget-form-participant-bar widget-form-participant-bar--uni" [style.width.%]="20">
                            <span>
                                {{ pl.goals_scored }} / <span *ngIf="!isHockey">{{ pl.matches_played }}</span>
                                <span *ngIf="isHockey">{{ pl.points }}</span> <br />
                            </span>
                        </div>
                        <div
                            class="widget-form-participant-bar widget-form-participant-bar--away"
                            [style.width.%]="pl.width"
                        >
                            <span *ngIf="!isHockey"> {{ pl.goal_average }}</span>
                            <span *ngIf="isHockey"> {{ (pl.goals_scored / pl.points) * 100 | number : '1.1-1' }}%</span>
                        </div>
                    </div>
                    <div class="widget-form-participant-text mb-3">
                        {{ pl.player_name }}
                    </div>
                </ng-container>
            </div>
            <div class="widget-form-participant-text ml-2">
                {{ 'web.playerstats_goals_scored' | translate }} /
                <span *ngIf="isHockey"> {{ 'web.playerstats_tooltip_points' | translate }}</span>
                <span *ngIf="!isHockey"> {{ 'web.playerstats_tooltip_matches_played' | translate }}</span>
            </div>

            <div class="widget-logo d-flex justify-content-center mt-4">
                <img
                    [src]="'/img/' + client.getName() + '.png'"
                    [alt]="client.getName()"
                    data-cy="logo"
                    class="widget-logo-client"
                />
            </div>
        </div>
    </div>
</ng-container>

/* eslint-disable camelcase */
import { Score } from '@interfaces/score.interface';

import { PlayerStatsInterface } from '@interfaces/player-stats.interface';

import { TimelineType } from '@/interfaces/match-data.interface';

export enum WidgetTypes {
    MATCHXS = 'match-xs-widget',
}

export type Result = 'win' | 'lose' | 'draw';
export type Periods = 'ht' | 'ft';

interface Base {
    id?: number | null;
    name: string;
    image: string;
}
export interface Match {
    date: string;
    home_id: number;
    home_team: string;
    away_id: number;
    away_team: string;
    away_image: string;
    tournament: string;
    flag: string;
    badge: string;
    score: Score;
    hfft?: Record<Periods, Result>;
}

export interface LastForm {
    [prop: string]: any;
    id: number;
    type: string;
    start_date: string;
    score: string;
    score_period_1: string;
    score_period_2?: string;
    score_period_3?: string;
    score_period_4?: string;
    score_period_5?: string;
    tournamentId: number;
    code_state: string;
    score_normal_time: string;
    home_team: string;
    away_team: string;
    corners_home?: number | null;
    corners_away?: number | null;
    sport_name?: string;
    status: Result;
    timeline?: {
        home_score: number;
        away_score: number;
        match_time: string;
        match_clock: string;
        period_type: string;
        competitor: 'home' | 'away';
        type: TimelineType;
        period: string
    }[];

}
export interface FormData extends Base {
    tennis_rankings_single?: number;
    form: LastForm[];
}

export interface AvgGoals {
    id?: number | null;
    name: string;
    image: string;
    avg_score: string;
    matches: Match[];
    periodsLength?: [number, number];
}

export interface LastMatches {
    date: string;
    home_id: 78028;
    home_team: string;
    home_image: string;
    away_id: 14657;
    away_team: string;
    away_image: string;
    tournament: string;
    flag: string;
    score: Score;
    badge: any;
    badge_class: string;
}


export type AvgData = { participant1: AvgGoals; participant2: AvgGoals };
export type Form = { home: FormData; away: FormData };

export type LastFormKey = keyof LastForm;

export interface GoalScored {
    home: { id: number; yes: boolean }[];
    away: { id: number; yes: boolean }[];
}


export interface TopScorer {
    home: { id: number; goals_for_team: number; full_name: string; modified: number };
    away: { id: number; goals_for_team: number; full_name: string; modified: number };
}

export type TopScorerStats = PlayerStatsInterface;

<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-match-container d-flex flex-column">
        <h1 *ngIf="!noTitle" class="avg-goals-title align-self-center">{{ 'web.widget_hfft_title' | translate }}</h1>
        <div class="avg-goals-team d-flex flex-column" *ngIf="data?.participant1 as p">
            <div class="d-flex avg-goals-team__container">
                <div class="avg-goals-team__name d-flex align-items-center">
                    <img
                        (error)="imageError($event)"
                        [attr.src]="p.image | responsive: 'participant':'medium'"
                        [alt]="p.name"
                        class="img-fluid app-cursor mr-1"
                    />
                    <div>{{ p.name }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-between avg-goals-team__container2">
                <div
                    class="avg-goals-team__score"
                    *ngFor="let match of p.matches; trackBy: trackByFn; first as isFirst; index as i"
                    [class.avg-goals-team__score--last]="isFirst"
                >
                    <div class="d-flex justify-content-center align-items-stretch hfft-team__bar">
                        <div
                            class="d-flex flex-column justify-content-between hfft-team__bar__field"
                            [ngClass]="resultCssClass('ht', match)"
                        >
                            <div>{{ p.periodsLength[0] }}'</div>
                            <div>{{ resultTrans('ht', match) }}</div>
                        </div>
                        <div
                            class="d-flex flex-column justify-content-between hfft-team__bar__field"
                            [ngClass]="resultCssClass('ft', match)"
                        >
                            <div>{{ p.periodsLength[1] }}'</div>
                            <div>{{ resultTrans('ft', match) }}</div>
                        </div>
                    </div>
                    <div class="avg-goals-team__score-text">
                        <ng-container *ngIf="isFirst; else notFirst">
                            {{ 'web.last_match' | translate }}
                        </ng-container>
                        <ng-template #notFirst>
                            {{ i + 1 }}
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <img [src]="'/img/' + client.getName() + '.png'" [alt]="client.getName()" class="widget-match-logo" />
        </div>

        <div class="avg-goals-team d-flex flex-column avg-goals-team--second" *ngIf="data?.participant2 as p">
            <div class="d-flex avg-goals-team__container">
                <div class="avg-goals-team__name d-flex align-items-center">
                    <img
                        (error)="imageError($event)"
                        [attr.src]="p.image | responsive: 'participant':'medium'"
                        [alt]="p.name"
                        class="img-fluid app-cursor mr-1"
                    />
                    <div>{{ p.name }}</div>
                </div>
            </div>
            <div class="d-flex justify-content-between avg-goals-team__container2">
                <div
                    class="avg-goals-team__score"
                    *ngFor="let match of p.matches; trackBy: trackByFn; first as isFirst; index as i"
                    [class.avg-goals-team__score--last]="isFirst"
                >
                    <div class="d-flex justify-content-center align-items-stretch hfft-team__bar">
                        <div
                            class="d-flex flex-column justify-content-between hfft-team__bar__field"
                            [ngClass]="resultCssClass('ht', match)"
                        >
                            <div>{{ p.periodsLength[0] }}'</div>
                            <div>{{ resultTrans('ht', match) }}</div>
                        </div>
                        <div
                            class="d-flex flex-column justify-content-between hfft-team__bar__field n"
                            [ngClass]="resultCssClass('ft', match)"
                        >
                            <div>{{ p.periodsLength[1] }}'</div>
                            <div>{{ resultTrans('ft', match) }}</div>
                        </div>
                    </div>
                    <div class="avg-goals-team__score-text">
                        <ng-container *ngIf="isFirst; else notFirst">
                            {{ 'web.last_match' | translate }}
                        </ng-container>
                        <ng-template #notFirst>
                            {{ i + 1 }}
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

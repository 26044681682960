<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<!-- <div *ngIf="!loaded" class="widget-page-loader"></div> -->
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <h1 *ngIf="!noTitle" class="widget-title align-self-center">{{ 'web.widget_form_title' | translate }}</h1>
        <div class="d-flex">
            <div class="widget-team mt-1 d-flex flex-column" *ngIf="data?.home as p" data-cy="team">
                <div class="widget-team__name d-flex align-items-center">
                    <img
                        (error)="imageError($event)"
                        [attr.src]="p.image | responsive: 'participant':'medium'"
                        [alt]="p.name"
                        class="img-fluid app-cursor mr-1"
                    />
                    <div>{{ p.name }}</div>
                </div>
                <div class="widget-team__form mt-1">
                    <app-last-matches [limit]="6" [matches]="p.form.reverse()" [spread]="true"></app-last-matches>
                </div>
            </div>

            <div class="widget-team mt-1 d-flex flex-column widget-team--second" *ngIf="data?.away as p" data-cy="team">
                <div class="widget-team__name d-flex align-items-center justify-content-end">
                    <div>{{ p.name }}</div>
                    <img
                        (error)="imageError($event)"
                        [attr.src]="p.image | responsive: 'participant':'medium'"
                        [alt]="p.name"
                        class="img-fluid app-cursor ml-1"
                    />
                </div>
                <div class="widget-team__form align-self-end mt-1">
                    <app-last-matches [limit]="6" [matches]="p.form.reverse()" [spread]="true"></app-last-matches>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column widget-form-participant-container">
            <widget-form-participants [formHome]="data?.home.form" [formAway]="data?.away.form">
                <span class="home">{{ data?.home?.name }}</span>
                <span class="away">{{ data?.away?.name }}</span>
            </widget-form-participants>
        </div>

        <div class="widget-logo d-flex justify-content-center mt-4">
            <img
                [src]="'/img/' + client.getName() + '.png'"
                [alt]="client.getName()"
                data-cy="logo"
                class="widget-logo-client"
            />
        </div>
    </div>
</ng-container>

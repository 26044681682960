/**
 *  Widgets - Match flash
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { interval, merge, Observable, Subscription, throwError, timer } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import CONFIG from '@widgets/config/widget.config';
import { isObject } from 'lodash-es';
import { DemoService } from '@widgets/services/demo.service';


import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import APP_CONFIG from '@config/app.config';

import { SwiperComponent } from 'swiper/angular';

import { SwiperOptions } from 'swiper';

import { WidgetTypes } from '@/modules/widgets/interfaces/widget.interface';
import { ClientService } from '@/services/client.service';


import { MatchData } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-match-flash',
    templateUrl: './match-xs.component.html',
    styleUrls: ['./styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MatchFlashComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

    public slideIndex: number = 0;

    public matches: MatchData[] = [];

    public matchesData: number[] = [];

    public disable: boolean = false;

    public slideConfig: SwiperOptions = {


    };

    private subscriber: Subscription;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            if (sub.br && sub.br.length > 0) {
                this.matchesData = sub.br;
            } else {
                this.finished();
            }
            this.load();
        });

        interval(8000).subscribe(() => {
            if (this.swiper?.swiperRef.isEnd) {
                this.swiper?.swiperRef.slideTo(0);
            } else {
                this.swiper?.swiperRef.slideNext();
            }
        });

        window.addEventListener(
            'message',
            (event: Record<string, any>) => {
                if ('action' in event.data && event.data.action === WidgetTypes.MATCHXS) {
                    if (isObject(event.data) && 'matchId' in event.data) {
                        const { matchId, source = null } = event.data as Record<string, any>;

                        this.demo.sourceIdToId(matchId, source).subscribe((ids: number[]) => {
                            if (ids.length > 0) {
                                this.matchesData.push(ids[0]);
                            }
                        });
                    } else if (isObject(event.data) && 'resetMatches' in event.data) {
                        this.matchesData.splice(0);
                        this.matches.splice(0);
                    }
                }
            },
            false,
        );
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    private load(): void {
        this.ngOnDestroy();
        const iso: string = this.lang.getLangSnapshot() ?? APP_CONFIG.defaultLang;
        this.subscriber = timer(0, environment.widgetMatchFlashReloadTime)
            .pipe(
                switchMap(() => {
                    const match: Observable<MatchData>[] = this.matchesData.map(
                        (id: number): Observable<MatchData> => this.match.getMatchNew(id, iso, true),
                    );
                    this.finished();
                    return merge(...match);
                }),
                filter((match: MatchData) => CONFIG.matchFlash.availSports.includes(match.sport_code_name)),

                catchError(
                    (e): Observable<any> => {
                        this.error();
                        return throwError(e);
                    },
                ),
            )
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .subscribe(
                (match: MatchData) => {
                    const index = this.matches.findIndex(m => m.id === match.id);
                    if (this.disable) {
                        return;
                    }
                    if (index !== -1) {
                        if (
                            match.score.home_team !== this.matches[index].score.home_team ||
                            match.score.away_team !== this.matches[index].score.away_team ||
                            match.code_state !== this.matches[index].code_state
                        ) {
                            this.slideIndex = index;
                        }

                        this.matches[index] = match;

                        this.finished();
                    } else {
                        this.matches.push(match);
                        this.finished();
                    }
                },
                () => {},
                () => {
                    this.finished();
                },
            );
    }
}

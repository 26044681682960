/**
 *  Form component both participants
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2021 livescore
 */

import { Component, Input, OnInit } from '@angular/core';
import { LastForm } from '@/modules/widgets/interfaces/widget.interface';

const num = 10;

@Component({
    selector: 'widget-win-probability',
    templateUrl: './win-probability.component.html',
})
export class WinProbabilityParticipantsComponent implements OnInit {
    @Input() home: string;
    @Input() away: string;
    @Input() draw: string;

    @Input() odds: number[];


    @Input()
    formHome: LastForm[];

    @Input()
    formAway: LastForm[];


    public count: number;

    formH: LastForm[] = [];
    formA: LastForm[] = [];

    public homePrct: number = 0;
    public awayPrct: number = 0;
    public drawPrct: number = 0;

    public ngOnInit(): void {
        this.formHome = this.formHome.filter((v:LastForm) => v.home_team === this.home).slice(0, num);
        this.formAway = this.formAway.filter((v:LastForm) => v.away_team === this.away).slice(0, num);

        let home = 0;
        let away = 0;
        // let draw = 0;

        this.count = Math.min(this.formHome.length, this.formAway.length);

        for (let x = 0; x < this.count; x += 1) {
            const hStatus = this.formHome[x].status;
            const aStatus = this.formAway[x].status;

            if (hStatus === 'win') {
                home += 1;
            }
            if (hStatus === 'lose') {
                away += 1;
            }
            if (aStatus === 'win') {
                away += 1;
            }
            if (aStatus === 'lose') {
                home += 1;
            }
            if (hStatus === 'draw') {
                // draw += 1;
            }
            if (aStatus === 'draw') {
                // draw += 1;
            }
        }

        if (this.isOdssValid) {
            const min = Math.min(...this.odds);

            let sum = 0;

            this.odds.forEach((val, key) => {
                if (val !== min) {
                    this.odds[key] = min / (val / min);
                    sum += this.odds[key];
                }
            });

            this.odds.forEach((val, key) => {
                if (val === min) {
                    sum += this.odds[key];
                }
            });

            this.homePrct = (this.odds[0] / sum) * 100;
            this.awayPrct = (this.odds[2] / sum) * 100;
            this.drawPrct = Math.max(100 - (Math.round(this.homePrct) + Math.round(this.awayPrct)), 0);

            // this.odds.forEach((val, key) => {
            //     if (val === min) {
            //         this.odds[key] = max;
            //     }
            //     if (val === max) {
            //         this.odds[key] = min;
            //     }
            // });


            // this.homePrct = (this.odds[0] / this.oddsSum) * 100;
            // this.awayPrct = (this.odds[2] / this.oddsSum) * 100;
            // this.drawPrct = Math.max(100 - (Math.round(this.homePrct) + Math.round(this.awayPrct)), 0);
        } else {
            this.homePrct = (home / (this.count * 2)) * 100;
            this.awayPrct = (away / (this.count * 2)) * 100;
            if (Math.round(this.homePrct) + Math.round(this.awayPrct) > 100) {
                if (this.homePrct > this.awayPrct || this.homePrct === this.awayPrct) {
                    this.awayPrct -= 1;
                } else {
                    this.homePrct -= 1;
                }
            }

            this.drawPrct = Math.max(100 - (Math.round(this.homePrct) + Math.round(this.awayPrct)), 0);
        }
        this.homePrct += 0.03;
        this.awayPrct += 0.02;
        this.drawPrct = Math.max(100 - (Math.round(this.homePrct) + Math.round(this.awayPrct)), 0);
    }

    get oddsSum(): number {
        if (this.isOdssValid) {
            return this.odds.reduce((acc, cur) => acc + cur);
        }
        return 0;
    }

    get isOdssValid(): boolean {
        return this.odds && Array.isArray(this.odds) && this.odds.length === 3;
    }

    get select1(): string {
        if (this.sort[0] === this.homePrct) {
            return this.home;
        }
        if (this.sort[0] === this.awayPrct) {
            return this.away;
        }
        return this.draw;
    }

    get select2(): string {
        if (this.sort[1] === this.homePrct) {
            return this.home;
        }
        if (this.sort[1] === this.awayPrct) {
            return this.away;
        }
        return this.draw;
    }

    get select3(): string {
        if (this.sort[2] === this.homePrct) {
            return this.home;
        }
        if (this.sort[2] === this.awayPrct) {
            return this.away;
        }
        return this.draw;
    }

    get class1(): string {
        if (this.sort[0] === this.homePrct) {
            return 'widget-win-probability--home';
        }
        if (this.sort[0] === this.awayPrct) {
            return 'widget-win-probability--away';
        }
        return 'widget-win-probability--draw';
    }

    get class2(): string {
        if (this.sort[1] === this.homePrct) {
            return 'widget-win-probability--home';
        }
        if (this.sort[1] === this.awayPrct) {
            return 'widget-win-probability--away';
        }
        return 'widget-win-probability--draw';
    }

    get class3(): string {
        if (this.sort[2] === this.homePrct) {
            return 'widget-win-probability--home';
        }
        if (this.sort[2] === this.awayPrct) {
            return 'widget-win-probability--away';
        }
        return 'widget-win-probability--draw';
    }

    get sort(): number[] {
        return [this.homePrct, this.awayPrct, this.drawPrct].sort((a, b) => a - b).reverse();
    }
}

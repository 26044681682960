<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="widget-title align-self-center">
                {{ 'web.widget_number_of_sets_head' | translate }}
            </h1>
            <div class="d-flex">
                <div class="widget-team mt-1 d-flex flex-column" *ngIf="data?.home as p" data-cy="team">
                    <div class="widget-team__name d-flex align-items-center">
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor mr-1"
                        />
                        <div>{{ p.name }}</div>
                    </div>
                </div>

                <div
                    class="widget-team mt-1 d-flex flex-column widget-team--second"
                    *ngIf="data?.away as p"
                    data-cy="team"
                >
                    <div class="widget-team__name d-flex align-items-center justify-content-end">
                        <div>{{ p.name }}</div>
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive: 'participant':'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor ml-1"
                        />
                    </div>
                </div>
            </div>

            <div class="m-3">
                <div class="form-group widget-form">
                    <select
                        data-cy="number-of-sets-select"
                        id="number-of-sets-select"
                        [formControl]="numControl"
                        class="form-control custom-select border-light2"
                    >
                        <option value="3">{{ 'web.tennis_sets_max_3' | translate }}</option>
                        <option value="5">{{ 'web.tennis_sets_max_5' | translate }}</option>
                    </select>
                </div>
            </div>

            <div class="widget-win-probability d-flex">
                <div class="widget-win-probability__main widget-win-probability--uni1" data-cy="cs-sum-1">
                    <span class="widget-win-probability-emph" data-cy="total1"
                        >{{ sort[0]['prct'] | number: '1.0-0' }}%</span
                    >
                    <br />
                    {{ sort[0]['set'] }} {{ 'web.sets' | translate }}
                </div>
                <div class="widget-win-probability__right d-flex flex-column flex-grow-1">
                    <div
                        class="widget-win-probability--uni2 d-flex justify-content-between"
                        [style.height.%]="numberOfSets === 5 ? 60 : 100"
                        data-cy="cs-sum-2"
                    >
                        <span class="widget-win-probability-emph2" data-cy="total2"
                            >{{ sort[1]['prct'] | number: '1.0-0' }}%</span
                        >
                        <span> {{ sort[1]['set'] }} {{ 'web.sets' | translate }}</span>
                    </div>
                    <div
                        class="widget-win-probability--uni3 flex-grow-1 d-flex justify-content-between"
                        data-cy="cs-sum-3"
                        *ngIf="numberOfSets === 5"
                    >
                        <span class="widget-win-probability-emph3">{{ sort[2]['prct'] | number: '1.0-0' }}%</span>
                        <span> {{ sort[2]['set'] }} {{ 'web.sets' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div
                        class="widget-form-participant widget-form-participant--home{{ i + 1 }}"
                        data-cy="csHome{{ i + 1 }}"
                        *ngFor="let item of actualList; trackBy: trackByIndex; index as i"
                    >
                        {{ getPrct('home', item) | number: '1.0-1' }}%
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home{{ i + 1 }} text-center"
                        data-cy="csHome{{ i + 1 }}"
                        *ngFor="let item of actualList; trackBy: trackByIndex; index as i"
                    >
                        {{ item }}
                    </div>
                </div>
                <div *ngIf="data?.home as p" class="text-center widget-form-participant--home f-14">
                    {{ p.name }}
                </div>
            </div>
            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div
                        class="widget-form-participant widget-form-participant--away{{ i + 1 }}"
                        data-cy="csAway{{ i + 1 }}"
                        *ngFor="let item of actualList; trackBy: trackByIndex; index as i"
                    >
                        {{ getPrct('away', item) | number: '1.0-1' }}%
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away{{ i + 1 }} text-center"
                        data-cy="csAway{{ i + 1 }}"
                        *ngFor="let item of actualList; trackBy: trackByIndex; index as i"
                    >
                        {{ item }}
                    </div>
                </div>
                <div *ngIf="data?.away as p" class="text-center widget-form-participant--away f-14">
                    {{ p.name }}
                </div>
            </div>
            <div class="w-100 text-center widget-form-participant-text app-word-nowrap">
                {{ 'web.num_of_matches' | translate }} ({{ num }})
            </div>
            <div class="widget-logo d-flex justify-content-center mt-4">
                <img
                    [src]="'/img/' + client.getName() + '.png'"
                    [alt]="client.getName()"
                    data-cy="logo"
                    class="widget-logo-client"
                />
            </div>
        </div>
    </div>
</ng-container>

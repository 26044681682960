<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="widget-title align-self-center">
                {{ 'web.widget_both_team_to_score_last_matches' | translate }}
            </h1>
            <div class="d-flex">
                <div class="widget-team mt-1 d-flex flex-column" *ngIf="data?.home as p" data-cy="team">
                    <div class="widget-team__name d-flex align-items-center">
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive : 'participant' : 'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor mr-1"
                        />
                        <div>{{ p.name }}</div>
                    </div>
                </div>

                <div
                    class="widget-team mt-1 d-flex flex-column widget-team--second"
                    *ngIf="data?.away as p"
                    data-cy="team"
                >
                    <div class="widget-team__name d-flex align-items-center justify-content-end">
                        <div>{{ p.name }}</div>
                        <img
                            (error)="imageError($event)"
                            [attr.src]="p.image | responsive : 'participant' : 'medium'"
                            [alt]="p.name"
                            class="img-fluid app-cursor ml-1"
                        />
                    </div>
                </div>
            </div>

            <!-- Clean sheet-->
            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--home" data-cy="streakHome">
                        {{ cleanHome() }}%
                    </div>
                    <div class="widget-form-participant-text align-self-end">
                        <ng-container *ngIf="!isHockey">
                            {{ 'web.widget_clean_sheet_prct' | translate }}
                        </ng-container>
                        <ng-container *ngIf="isHockey">
                            {{ 'web.widget_clean_sheet_hockey_prct' | translate }}
                        </ng-container>
                    </div>
                    <div class="widget-form-participant widget-form-participant--away" data-cy="streakAway">
                        {{ cleanAway() }}%
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home"
                        [style.width.%]="homeWidth()"
                    >
                        {{ data?.home?.name }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away"
                        [style.width.%]="awayWidth()"
                    >
                        {{ data?.away?.name }}
                    </div>
                </div>
            </div>

            <!-- Score sheet-->
            <div class="d-flex flex-column widget-form-participant-container">
                <div class="d-flex justify-content-between">
                    <div class="widget-form-participant widget-form-participant--home" data-cy="scoreHome">
                        {{ scoredHome() }}%
                    </div>
                    <div class="widget-form-participant-text align-self-end">
                        {{ 'web.widget_goal_scored_prct' | translate }}
                    </div>
                    <div class="widget-form-participant widget-form-participant--away" data-cy="scoreAway">
                        {{ scoredAway() }}%
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--home"
                        [style.width.%]="homeWidth2()"
                    >
                        {{ data?.home?.name }}
                    </div>
                    <div
                        class="widget-form-participant-bar widget-form-participant-bar--away"
                        [style.width.%]="awayWidth2()"
                    >
                        {{ data?.away?.name }}
                    </div>
                </div>
            </div>

            <!-- Periods-->
            <ng-container *ngIf="isHockey">
                <div class="mt-5">
                    <div
                        class="d-flex widget-form-participant-container"
                        *ngFor="let period of periods; trackBy: trackByIndex"
                    >
                        <div class="flex-fill">
                            <h2 class="text-center widget-form-participant-container-h2 --ext">
                                {{ 'status.ice-hockey.period_' + period | translate }}
                            </h2>

                            <div class="d-flex">
                                <div
                                    class="widget-form-participant-bar widget-form-participant-bar--home"
                                    [style.width.%]="width('homeWidth', period)"
                                >
                                    {{ clean('home', period) }}%
                                </div>
                                <div
                                    class="widget-form-participant-bar widget-form-participant-bar--away"
                                    [style.width.%]="width('awayWidth', period)"
                                >
                                    {{ clean('away', period) }}%
                                </div>
                            </div>
                            <div class="widget-form-participant-text text-center">
                                {{ 'web.widget_clean_sheet_hockey_prct' | translate }}
                            </div>

                            <div class="d-flex">
                                <div
                                    class="widget-form-participant-bar widget-form-participant-bar--home"
                                    [style.width.%]="width('homeWidth2', period)"
                                >
                                    {{ scored('home', period) }}%
                                </div>
                                <div
                                    class="widget-form-participant-bar widget-form-participant-bar--away"
                                    [style.width.%]="width('awayWidth2', period)"
                                >
                                    {{ scored('away', period) }}%
                                </div>
                            </div>
                            <div class="widget-form-participant-text text-center">
                                {{ 'web.widget_goal_scored_prct' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="w-100 text-center widget-form-participant-text app-word-nowrap">
                {{ 'web.num_of_matches' | translate }} ({{ num }})
            </div>

            <div class="widget-logo d-flex justify-content-center mt-4">
                <img
                    [src]="'/img/' + client.getName() + '.png'"
                    [alt]="client.getName()"
                    data-cy="logo"
                    class="widget-logo-client"
                />
            </div>
        </div>
    </div>
</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { OverlayType } from '@widgets/interfaces/page.interface';

const Messages = {
    [OverlayType.ERROR]: 'web.widgets_error',
    [OverlayType.ERROREMPTY]: 'web.widgets_error_empty',
    [OverlayType.ERROR_MATCH]: 'web.widgets_error_match',
    [OverlayType.MATCH_TICKET]: 'web.widgets_add_match',
    [OverlayType.NO_MATCH]: 'web.widgets_no_match',
};
const Icons = {
    [OverlayType.ERROR]: 'fas fa-exclamation-triangle',
    [OverlayType.ERROREMPTY]: 'fas fa-exclamation-triangle',
    [OverlayType.ERROR_MATCH]: 'fas fa-exclamation-triangle',
    [OverlayType.MATCH_TICKET]: 'far fa-arrow-alt-circle-down fa-3x',
    [OverlayType.NO_MATCH]: 'fas fa-info fa-3x',
};

@Component({
    selector: 'app-widgets-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['overlay.scss'],
})
export class OverlayComponent implements OnInit {
    @Input()
    type: OverlayType = OverlayType.ERROR;

    constructor() {}

    ngOnInit(): void {

    }

    public getMessage(): string {
        return Messages[this.type];
    }

    public getIcon(): string {
        return Icons[this.type];
    }
}

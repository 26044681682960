<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay *ngIf="!loadedError && loaded && !data" type="no-match"></app-widgets-overlay>
<!-- <div *ngIf="!loaded" class="widget-page-loader"></div> -->
<ng-container *ngIf="loaded">
    <div class="widget-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="widget-title align-self-center">
                {{ 'web.widget_win_probability_title' | translate }}
            </h1>

            <widget-win-probability
                [formHome]="dataWin?.home.form.reverse()"
                [formAway]="dataWin?.away.form.reverse()"
                [home]="data?.home?.name"
                [away]="data?.away?.name"
                [draw]="drawTrans"
                [odds]="odds"
            >
            </widget-win-probability>

            <div class="d-flex flex-column widget-form-participant-container">
                <widget-form-participants [formHome]="data?.home.form" [formAway]="data?.away.form">
                    <span class="home">{{ data?.home?.name }}</span>
                    <span class="away">{{ data?.away?.name }}</span>
                </widget-form-participants>
            </div>

            <div class="d-flex">
                <div class="widget-team d-flex flex-column" *ngIf="data?.home as p" data-cy="team">
                    <div class="widget-team__name d-flex align-items-center"></div>
                    <div class="widget-team__form mt-1">
                        <app-last-matches [limit]="6" [matches]="p.form" [spread]="true"></app-last-matches>
                    </div>
                </div>

                <div class="widget-team d-flex flex-column widget-team--second" *ngIf="data?.away as p" data-cy="team">
                    <div class="widget-team__name d-flex align-items-center justify-content-end"></div>
                    <div class="widget-team__form align-self-end mt-1">
                        <app-last-matches [limit]="6" [matches]="p.form" [spread]="true"></app-last-matches>
                    </div>
                </div>
            </div>

            <div class="widget-logo d-flex justify-content-center mt-4">
                <img
                    [src]="'/img/' + client.getName() + '.png'"
                    [alt]="client.getName()"
                    data-cy="logo"
                    class="widget-logo-client"
                />
            </div>
        </div>
    </div>
</ng-container>

<app-widgets-overlay *ngIf="loadedError" [type]="loadedErrorReason"></app-widgets-overlay>
<app-widgets-overlay
    *ngIf="!loadedError && loaded && (data?.length === 0 || !data)"
    type="no-match"
></app-widgets-overlay>

<ng-container *ngIf="loaded">
    <div class="widget-match-container d-flex flex-column">
        <div class="widget-container__object">
            <h1 *ngIf="!noTitle" class="last-matches-title align-self-center mb-3">
                {{ 'web.widget_last_matches' | translate }}
            </h1>
            <div
                class="d-flex last-matches-match align-items-center flex-wrap"
                *ngFor="let match of data; trackBy: trackByFn"
            >
                <div class="last-matches-match__time">
                    {{ match.date | livDate: 'datetime3' }}
                </div>
                <div class="last-matches-match__team">
                    <span [class.last-matches-match__team--emph]="isWinnerHome(match)">
                        <img [src]="match.home_image" class="img-fluid" [alt]="match.home_team" />
                        {{ match.home_team }}</span
                    >
                    -
                    <span [class.last-matches-match__team--emph]="isWinnerAway(match)">
                        {{ match.away_team }}
                        <img [src]="match.away_image" class="img-fluid" [alt]="match.away_team" />
                    </span>
                </div>
                <div class="last-matches-match__score">{{ match.score.home_team }} - {{ match.score.away_team }}</div>
                <div class="last-matches-match__badge">
                    <!-- <div class="last-matches-match__badge--win" >W</div>
                 <div class="last-matches-match__badge--lose">L</div>
                 <div class="last-matches-match__badge--tie">D</div> -->
                </div>
            </div>

            <div class="d-flex justify-content-center mt-2">
                <img [src]="'/img/' + client.getName() + '.png'" [alt]="client.getName()" class="widget-match-logo" />
            </div>
        </div>
    </div>
</ng-container>

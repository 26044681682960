import { MatchData, MatchState } from '@/interfaces/match-data.interface';

type Constructor<T = Record<any, any>> = new (...args: any[]) => T;

export const WidgetMixin = <T extends Constructor>(base: T): T => class extends base {
    constructor(...args: any[]) {
        super(...args);
    }

    /**
         * Check if home team is winner
         * @return {boolean}
         */
    public isWinnerHome(data: MatchData): boolean {
        return (data.match_state === MatchState.AFTER || MatchState.CANCELED) && data.winner === 1;
    }

    /**
         * Check if away team is winner
         * @return {boolean}
         */
    public isWinnerAway(data: MatchData): boolean {
        return (data.match_state === MatchState.AFTER || data.match_state === MatchState.CANCELED) && data.winner === 2;
    }

    /**
         * Check if away team is live
         * @return {boolean}
         */
    public isRunning(data: MatchData): boolean {
        return data.match_state === MatchState.INPLAY;
    }

    public isLive(data: MatchData): boolean {
        return data.match_state === MatchState.INPLAY || data.match_state === MatchState.INPLAYPAUSE;
    }
};

/**
 *  Widgets - 2way handicap tennis
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2022 livescore
 */

import { MatchService } from '@services/match.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';

import CONFIG from '@widgets/config/widget.config';

import { MatchType } from '@/interfaces/league-table.interface';
import { ClientService } from '@/services/client.service';
import { CodeState, MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-2way-handicap-tennis',
    templateUrl: './2way-handicap-tennis.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class Handicap2WayTennisComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];

    private matchId: number;

    private subscriber: Subscription;

    public data: Form | null = null;

    public type: string = 'default';

    private periods: string[] = [
        'score_period_1',
        'score_period_2',
        'score_period_3',
        'score_period_4',
        'score_period_5',
    ];

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
        private ref: ChangeDetectorRef,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num;

            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }

    public getWonLost(team: 'home' | 'away'): [number, number] {
        let gameWon = 0;
        let gameLost = 0;
        let total = 0;

        const reversed =
            this.data![team].form.length > this.num ? this.data![team].form.slice(1) : this.data![team].form;
        reversed.forEach((f) => {
            if (f.code_state === CodeState.ENDED && f.score) {
                const isHome = this.data![team].name === f.home_team;

                this.periods.forEach((period: string): void => {
                    if (f[period]) {
                        const [homePoint, awayPoint] = f[period].split(':');

                        if (homePoint && isHome) {
                            gameWon += +homePoint;
                        } else if (awayPoint && !isHome) {
                            gameWon += +awayPoint;
                        }

                        if (homePoint && !isHome) {
                            gameLost += +homePoint;
                        } else if (awayPoint && isHome) {
                            gameLost += +awayPoint;
                        }
                    }
                });

                total += 1;
            }
        });

        return [gameWon / total ?? 0, gameLost / total ?? 0];
    }

    get homeWon(): number {
        return this.getWonLost('home')[0];
    }

    get homeLost(): number {
        return this.getWonLost('home')[1];
    }

    get awayWon(): number {
        return this.getWonLost('away')[0];
    }

    get awayLost(): number {
        return this.getWonLost('away')[1];
    }

    get widthHomeWin(): number {
        const total = this.homeWon + this.homeLost;
        return Math.round((this.homeWon / total) * 100);
    }

    get widthHomeLost(): number {
        return 100 - this.widthHomeWin;
    }

    get widthAwayWin(): number {
        const total = this.awayWon + this.awayLost;
        return Math.round((this.awayWon / total) * 100);
    }

    get widthAwayLost(): number {
        return 100 - this.widthAwayWin;
    }

    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null | undefined = null;
        let awayId: number | null | undefined = null ?? undefined;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.hadndicap2way.availSports.includes(match.sport_code_name)),
                mergeMap((match: MatchData): Observable<Form> => {
                    const [p1, p2] = match.participants.map((p: Team): number => p.id);
                    homeId = p1;
                    awayId = p2;
                    return this.demo.form(p1, p2, iso as string, { limit: this.num });
                }),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError((e): Observable<any> => {
                    this.error(e);
                    return throwError(e);
                }),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                },
                () => {
                    this.error();
                },
                () => {
                    if (!this.data) {
                        this.error();
                    }
                    this.loaded = true;
                    this.finished();
                },
            );
    }
}

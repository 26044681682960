/**
 *  Widgets - Cards
 *  @module Widgets
 *  @author Livescore <info@score-stats.com>
 *  @copyright 2023 livescore
 */

import { MatchService } from '@services/match.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@widgets/pages/base.component';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { DemoService } from '@widgets/services/demo.service';
import { WidgetMixin } from '@widgets/services/mixins/widget.mixin';

import { Form, LastForm } from '@widgets/interfaces/widget.interface';
import CONFIG from '@widgets/config/widget.config';

import { every, reduce } from 'lodash-es';

import { MatchType } from '@/interfaces/league-table.interface';
import { ClientService } from '@/services/client.service';
import { MatchData, Team } from '@/interfaces/match-data.interface';
import { LangService } from '@/services/lang.service';

@Component({
    selector: 'widgets-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['../styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CardsComponent extends WidgetMixin(BaseComponent) implements OnInit, OnDestroy {
    public matches: MatchData[] = [];
    public data: Form | null = null;
    public num: number;
    private matchId: number;
    private subscriber: Subscription;

    public constructor(
        private router: ActivatedRoute,
        private match: MatchService,
        private lang: LangService,
        private demo: DemoService,
        public client: ClientService,
    ) {
        super(demo);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        this.setTitle(this.router);

        this.router.data.subscribe((sub) => {
            this.num = sub.num ?? 10;

            if (sub.br && sub.br.length > 0) {
                [this.matchId] = sub.br;
            } else {
                this.finished();
            }

            if (this.matchId) {
                this.load();
            } else {
                this.error();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }

        this.matches.splice(0);
    }


    public cards(type: 'home' | 'away', count: 'total' | 'yellow' | 'red',
        totalCount: boolean = false): number | string {
        let total = 0;
        let cards = 0;

        const reversed = this.data![type].form.length > this.num
            ? this.data![type].form.slice(1) : this.data![type].form;

        if (every(reversed, { corners_home: null, corners_away: null })) {
            return 'Ndf';
        }

        cards = reduce(
            reversed,
            (sum: number, f) => {
                let numCards = 0;

                if (f.code_state !== 'not_started'
                ) {
                    const isHome = this.data![type].name === f.home_team;

                    f.red_cards_home = f.red_cards_home ? +f.red_cards_home : 0;
                    f.red_cards_away = f.red_cards_away ? +f.red_cards_away : 0;
                    f.yellow_cards_home = f.yellow_cards_home ? +f.yellow_cards_home : 0;
                    f.yellow_cards_away = f.yellow_cards_away ? +f.yellow_cards_away : 0;
                    f.yellow_red_cards_home = f.yellow_red_cards_home ? +f.yellow_red_cards_home : 0;
                    f.yellow_red_cards_away = f.yellow_red_cards_away ? +f.yellow_red_cards_away : 0;

                    let cardsSum;
                    if (isHome) {
                        if (count === 'total') {
                            cardsSum = +f.red_cards_home + +f.yellow_cards_home + +f.yellow_red_cards_home;
                        } else if (count === 'red') {
                            cardsSum = +f.red_cards_home + +f.yellow_red_cards_home;
                        } else if (count === 'yellow') {
                            cardsSum = f.yellow_cards_home;
                        }

                        numCards += cardsSum || 0;
                    } else {
                        if (count === 'total') {
                            cardsSum = +f.red_cards_away + +f.yellow_cards_away + +f.yellow_red_cards_away;
                        } else if (count === 'red') {
                            cardsSum = +f.red_cards_away + +f.yellow_red_cards_away;
                        } else if (count === 'yellow') {
                            cardsSum = f.yellow_cards_away;
                        }
                        numCards += cardsSum || 0;
                    }
                    total += 1;
                }
                return sum + numCards;
            },
            0,
        );

        if (totalCount) {
            return cards;
        }

        return cards / total;
    }


    public homeWidth(count: 'total' | 'yellow' | 'red', totalCount: boolean = false): number {
        const home = this.cards('home', count, totalCount);
        const away = this.cards('away', count, totalCount);
        return Math.round((+home / (+home + +away)) * 100);
    }

    public awayWidth(count: 'total' | 'yellow' | 'red', totalCount: boolean = false): number {
        return 100 - this.homeWidth(count, totalCount);
    }


    private load(): void {
        const iso: string | null = this.lang.getLangSnapshot();
        let homeId: number | null = null;
        let awayId: number | null = null;

        this.subscriber = this.match
            .getMatchNew(this.matchId, iso as string, true)
            .pipe(
                filter((match: MatchData) => CONFIG.cards.availSports.includes(match.sport_code_name)),
                mergeMap(
                    (match: MatchData): Observable<Form> => {
                        const [p1, p2] = match.participants.map((p: Team): number => p.id);
                        homeId = p1;
                        awayId = p2;
                        return this.demo.form(p1, p2, iso, { limit: this.num });
                    },
                ),
                map((data: Form) => {
                    data.home = { ...data.home, id: homeId };
                    data.away = { ...data.away, id: awayId };

                    data.home.form = data.home.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    data.away.form = data.away.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                    return data;
                }),
                switchMap(this.checkEmpty()),
                catchError(
                    (e): Observable<any> => {
                        this.error(e);
                        return throwError(e);
                    },
                ),
            )

            .subscribe(
                (data) => {
                    this.data = data;
                    this.finished();
                },
                () => {},
                () => {
                    this.loaded = true;
                },
            );
    }
}
